import {
  Button,
  Container,
  Grid,
  Modal,
  Table,
  TableCell,
  TableRow,
  useModal,
} from '@aurecon-creative-technologies/styleguide'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchIRRNPVDetails } from 'src/api/ExecutionsService'
import Style from '../../styles/Execution.module.sass'
import NVPmodel from './NPVmodel'
import { IResponse } from 'src/models/api/IResponse'
import { IRRNPVCalcModel } from 'src/models/api/IRRNPVCalcModel'
import { ExecutionsDataMapping, IRRNVPModalHeaders } from 'src/enums/ExecutionEnum'
import { IRRNVPListModal, IRRNVPModalData } from 'src/models/api/IRRNPVCalcResponseModel'
import { appInsights } from 'src/api/AppInsights'
import { useRecoilValue } from 'recoil'
import { selectedProjectIdState } from 'src/stores/AppStore'

const IRRNVPCalculation = () => {
  if (appInsights) appInsights.trackPageView({ name: 'IRR_NVP Calculation' })
  const navigate = useNavigate()

  const { isShowing, toggleModal } = useModal()
  const { executionId } = useParams<{ executionId: string }>()
  const projectId = useRecoilValue(selectedProjectIdState)
  const [currentModalData, setCurrentModalData] = useState<{ data: IRRNVPModalData[]; label: string } | null>(null)
  const [irrnvpFormatedData, setIrrnvpFormatedData] = useState<IRRNVPListModal[] | null>(null)

  const createNPVData = useCallback((response: IResponse<IRRNPVCalcModel[]>) => {
    return ExecutionsDataMapping.map(({ label, key, dataKey, yearKey = 'year', valueKey = 'present_value' }) => ({
      label,
      value: response[key],
      modalData:
        response[dataKey]?.map((item: IRRNVPListModal) => ({ year: item[yearKey], present_value: item[valueKey] })) ||
        [],
    }))
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchIRRNPVDetails(Number(executionId))
        if (response !== undefined && response != null) {
          setIrrnvpFormatedData(createNPVData(response))
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [executionId, createNPVData])

  const handleViewDetails = (modalData: IRRNVPModalData[], label: string) => {
    setCurrentModalData({ data: modalData, label })
    toggleModal()
  }

  const handleGoBack = () => {
    navigate(`/dashboard/${projectId}/executionlist`)
  }

  return (
    <Container fluid>
      <header className={Style.header}>
        <Container fluid cssClass={Style.paddingX}>
          <div className={Style.header_wrapper}>
            <div className={Style.go_back}>
              <Button
                type='custom'
                cssClass={Style.goBackBtn}
                label='Go back'
                icon='arrow_back_ios'
                size='small'
                onClick={handleGoBack}
              />
              <h2>IRR/NPV calculation</h2>
            </div>
          </div>
        </Container>
      </header>
      <div className={Style.marginBtm}>
        <section className={Style.nvp_wrapper}>
          <Container fluid cssClass={Style.paddingX}>
            <div className={`${Style.tbl_container} ${Style.nvp_grid} nvp_grid`}>
              {irrnvpFormatedData && irrnvpFormatedData.length > 0 && (
                <Table headers={IRRNVPModalHeaders}>
                  {irrnvpFormatedData.map((row) => (
                    <TableRow key={row.label}>
                      <TableCell cellClass={Style.tableDataPadding}>{row.label}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{row.value}</TableCell>
                      <TableCell cellClass={Style.reduceBorderSize}>
                        <Grid row gap={12}>
                          <Grid item right cell>
                            <Button
                              type='custom'
                              size='small'
                              label='View Detailed Calculation'
                              cssClass={Style.btnViewDetails}
                              onClick={() => handleViewDetails(row.modalData, row.label)}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              )}
              <Modal
                isShowing={isShowing}
                onClose={toggleModal}
                actions={[]}
                shouldOverlayClose={false}
                isCloseButton={true}
                size={'medium'}
              >
                <NVPmodel modalData={currentModalData?.data || []} label={currentModalData?.label || ''} />
              </Modal>
            </div>
          </Container>
        </section>
      </div>
    </Container>
  )
}

export default IRRNVPCalculation
