import { ChartConfiguration } from 'chart.js'
import { Chart } from 'chart.js/auto'
import React, { useEffect, useRef } from 'react'
import { SampleDayFCASPriceColors } from 'src/config/colors'

interface SampleDayFCASPriceChartProps {
  data: ChartConfiguration<'line'>['data']
}

const SampleDayFCASPriceChart: React.FC<SampleDayFCASPriceChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null)
  const chartInstanceRef = useRef<Chart<'line'> | null>(null)

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d')
    if (!ctx || !data) return

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy()
    }

    const datasets = data.datasets.map((dataset, index) => ({
      ...dataset,
      borderColor: SampleDayFCASPriceColors[index],
    }))

    const chartConfig: ChartConfiguration<'line'> = {
      type: 'line',
      data: {
        ...data,
        datasets: datasets,
      },
      options: {
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Time of the Day (hours)',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Market Price ($/MWh)',
            },
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              boxHeight: 1,
              boxWidth: 30,
              // usePointStyle: true, // This makes the legend items use line point styles
            },
          },
          tooltip: {
            mode: 'nearest',
            intersect: false,
          },
          title: {
            display: true,
            text: 'Sample day of FCAS price data',
            font: {
              size: 14,
              weight: 'normal',
            },
            padding: {
              top: 10,
              bottom: 20,
            },
          },
          datalabels: {
            display: false,
          },
        },
      },
    }

    chartInstanceRef.current = new Chart(ctx, chartConfig)

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
    }
  }, [data])

  return <canvas ref={chartRef} width='600' height='400'></canvas>
}

export default SampleDayFCASPriceChart
