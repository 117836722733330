import { Button, Container, Grid, Loader, Pagination, Text } from '@aurecon-creative-technologies/styleguide'
import { startTransition, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import ProjectCard from 'src/components/ProjectCard'
import SearchFilter from 'src/components/SearchFilter'
import { DEFAULTPAGINATIONDISPLAYPAGES } from 'src/config/constant'
import { SortingItems } from 'src/enums/SortingItems'
import { Project } from 'src/models/api/Projects'
import {
  currentPageState,
  inProductedSecretState,
  projectDataSelector,
  selectedProjectIdState,
} from 'src/stores/AppStore'
import Style from '../styles/Projects.module.sass'
import { isRoleEmpty } from 'src/helpers/appRoles'

const Projects = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Projects' })
  const navigate = useNavigate()
  const SecretState = useRecoilValue(inProductedSecretState)
  const setSelectedProjectId = useSetRecoilState(selectedProjectIdState)
  const [currentPage, setCurrentPage] = useRecoilState(currentPageState)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [projects, setProjects] = useState<Project[]>([])
  const projectData = useRecoilValueLoadable(projectDataSelector)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setSelectedProjectId(1)
    if (projectData.state === 'hasValue' && projectData.contents) {
      startTransition(() => {
        if (projectData.contents['items'] !== undefined) {
          setProjects(projectData.contents['items'])
          setTotalPages(projectData.contents['pages'])
          setTotalCount(projectData.contents['total'])
        } else {
          setProjects([])
          setTotalPages(1)
        }
      })
    }
  }, [projectData, setSelectedProjectId])

  useEffect(() => {
    if (projectData.state === 'loading') {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [projectData.state])

  const handleNewProjectClick = () => {
    navigate('/createproject')
  }

  const handlePaginationClick = (page: number) => {
    setCurrentPage(page === 0 ? 1 : page)
  }
  return (
    <Container fluid>
      <header className={Style.header}>
        <Container fluid cssClass={Style.paddingX}>
          <div className={Style.header_wrapper}>
            <h1 className={Style.titleHead}>Projects</h1>
            {SecretState && !isRoleEmpty(SecretState.role) && (
              <Button
                type='custom'
                label='New Project'
                onClick={handleNewProjectClick}
                cssClass={Style.btnNewProject}
              />
            )}
          </div>
        </Container>
      </header>
      <Container fluid cssClass={Style.paddingX} style={{ gap: '20px' }}>
        <div>
          <section>
            <SearchFilter dropdownItems={SortingItems} placeholder={'Search for a Project'} sortText='Sort' />
          </section>

          <Container fluid>
            {isLoading && (
              <div className={Style.loaderWrapper}>
                <Loader />
              </div>
            )}
            {!isLoading && projects.length === 0 ? (
              <Text type='h3' cssClass={Style.noProject}>
                There are currently no project setup. Please create a project{' '}
              </Text>
            ) : (
              <Grid row gap={6} center>
                {!isLoading && projects.map((item) => <ProjectCard key={item['id']} data={item} />)}
              </Grid>
            )}
          </Container>
        </div>
        {!isLoading && projects.length !== 0 && (
          <div className={Style.paginationCont}>
            <div className={Style.paginationButtons}>
              <Pagination
                page={currentPage}
                pageCount={totalPages}
                displayPages={DEFAULTPAGINATIONDISPLAYPAGES}
                onChange={handlePaginationClick}
                prevButtonTitle='Previous page'
                nextButtonTitle='Next page'
                cssClass={Style.paginatCont}
                showJumpToInput
              />
            </div>
            <div className={Style.totalPageCount}>
              {currentPage} - {totalPages} of {totalCount} Projects
            </div>
          </div>
        )}
      </Container>
    </Container>
  )
}

export default Projects
