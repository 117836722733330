import { Grid, Checkbox, Table, TableRow, TableCell, FormInput } from '@aurecon-creative-technologies/styleguide'
import { useCallback, useEffect } from 'react'
import { CapContractsInterface, ScenarioFormData } from 'src/models/ScenarioFormData'
import { QuarterCapVolume } from 'src/enums/ScenarioEnums'
import { getCurrentDateISOString } from 'src/helpers/utils'
import Style from '../../styles/CreateScenario.module.sass'

const CapContractHeader = [
  { label: QuarterCapVolume.YEAR },
  { label: QuarterCapVolume.Q1_CAP_VOLUME },
  { label: QuarterCapVolume.Q1_CAP_PRICE },
  { label: QuarterCapVolume.Q1_STRIKE_PRICE },
  { label: QuarterCapVolume.Q2_CAP_VOLUME },
  { label: QuarterCapVolume.Q2_CAP_PRICE },
  { label: QuarterCapVolume.Q2_STRIKE_PRICE },
  { label: QuarterCapVolume.Q3_CAP_VOLUME },
  { label: QuarterCapVolume.Q3_CAP_PRICE },
  { label: QuarterCapVolume.Q3_STRIKE_PRICE },
  { label: QuarterCapVolume.Q4_CAP_VOLUME },
  { label: QuarterCapVolume.Q4_CAP_PRICE },
  { label: QuarterCapVolume.Q4_STRIKE_PRICE },
]

interface Step3Props {
  formData: ScenarioFormData
  setFormData: React.Dispatch<React.SetStateAction<ScenarioFormData>>
  errors: Record<string, string>
}

export const Step3: React.FC<Step3Props> = ({ formData, setFormData, errors }) => {
  const handleChange = (value: string | number | boolean, name: string) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleCapContracts = (id: number, field: string, value: string) => {
    const updatedCollecteData = formData.cap_contracts.map((cap) => {
      if (cap.year === id) {
        return {
          ...cap,
          [field]: value,
        }
      }
      return cap
    })

    setFormData({
      ...formData,
      cap_contracts: updatedCollecteData,
    })
  }

  const addOrRemoveContracts = useCallback(() => {
    const numberOfObjectsToAdd = Number(formData.project_life_years) + 1
    const currentContractsLength = formData.cap_contracts.length

    if (!isNaN(numberOfObjectsToAdd)) {
      const updatedContracts = formData.cap_contracts.map((factor, index) => {
        const newYear = Number(formData.first_year_of_commercial_operation) + index
        return {
          ...factor,
          year: newYear,
        }
      })
      for (let i = currentContractsLength; i < numberOfObjectsToAdd; i++) {
        const newYear = Number(formData.first_year_of_commercial_operation) + i
        const newContractsObj: CapContractsInterface = {
          created_at: getCurrentDateISOString(),
          updated_at: getCurrentDateISOString(),
          created_by: getCurrentDateISOString(),
          updated_by: getCurrentDateISOString(),
          operating_year: i,
          year: newYear,
          q1_cap_volume: '0',
          q1_cap_price: '0',
          q1_cap_strike_price: '0',
          q2_cap_volume: '0',
          q2_cap_price: '0',
          q2_cap_strike_price: '0',
          q3_cap_volume: '0',
          q3_cap_price: '0',
          q3_cap_strike_price: '0',
          q4_cap_volume: '0',
          q4_cap_price: '0',
          q4_cap_strike_price: '0',
        }
        updatedContracts.push(newContractsObj)
      }

      const shouldUpdate =
        updatedContracts.length !== formData.cap_contracts.length ||
        updatedContracts.some((factor, index) => factor.year !== formData.cap_contracts[index]?.year)

      if (shouldUpdate) {
        setFormData((prevData) => ({
          ...prevData,
          cap_contracts: updatedContracts.slice(0, numberOfObjectsToAdd),
        }))
      }
    }
  }, [formData.project_life_years, formData.first_year_of_commercial_operation, formData.cap_contracts, setFormData])

  useEffect(() => {
    addOrRemoveContracts()
  }, [addOrRemoveContracts])

  return (
    <div className='mainContainerBlock'>
      <h2>Cap Contracts</h2>
      <Grid row gap={12}>
        <Grid item lg={12}>
          <Checkbox
            label='Cap contracting is intended'
            checked={formData.Cap_contracting_is_intended}
            onChange={(e: boolean) => handleChange(e, 'Cap_contracting_is_intended')}
          />
        </Grid>

        <Grid item lg={12}>
          <Table headers={CapContractHeader}>
            {formData.cap_contracts.map((data) => (
              <TableRow key={data.operating_year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.q1_cap_volume.toString()}
                    onChange={(v) => handleCapContracts(data.year, 'q1_cap_volume', v)}
                    required={formData.Cap_contracting_is_intended}
                    readonly={!formData.Cap_contracting_is_intended}
                    error={errors[`cap_contracts[${data.operating_year - 1}].q1_cap_volume`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.q1_cap_price.toString()}
                    onChange={(v) => handleCapContracts(data.year, 'q1_cap_price', v)}
                    required={formData.Cap_contracting_is_intended}
                    readonly={!formData.Cap_contracting_is_intended}
                    error={errors[`cap_contracts[${data.operating_year - 1}].q1_cap_price`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.q1_cap_strike_price.toString()}
                    onChange={(v) => handleCapContracts(data.year, 'q1_cap_strike_price', v)}
                    required={formData.Cap_contracting_is_intended}
                    readonly={!formData.Cap_contracting_is_intended}
                    error={errors[`cap_contracts[${data.operating_year - 1}].q1_cap_strike_price`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.q2_cap_volume.toString()}
                    onChange={(v) => handleCapContracts(data.year, 'q2_cap_volume', v)}
                    required={formData.Cap_contracting_is_intended}
                    readonly={!formData.Cap_contracting_is_intended}
                    error={errors[`cap_contracts[${data.operating_year - 1}].q2_cap_volume`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.q2_cap_price.toString()}
                    onChange={(v) => handleCapContracts(data.year, 'q2_cap_price', v)}
                    required={formData.Cap_contracting_is_intended}
                    readonly={!formData.Cap_contracting_is_intended}
                    error={errors[`cap_contracts[${data.operating_year - 1}].q2_cap_price`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.q2_cap_strike_price.toString()}
                    onChange={(v) => handleCapContracts(data.year, 'q2_cap_strike_price', v)}
                    required={formData.Cap_contracting_is_intended}
                    readonly={!formData.Cap_contracting_is_intended}
                    error={errors[`cap_contracts[${data.operating_year - 1}].q2_cap_strike_price`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.q3_cap_volume.toString()}
                    onChange={(v) => handleCapContracts(data.year, 'q3_cap_volume', v)}
                    required={formData.Cap_contracting_is_intended}
                    readonly={!formData.Cap_contracting_is_intended}
                    error={errors[`cap_contracts[${data.operating_year - 1}].q3_cap_volume`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.q3_cap_price.toString()}
                    onChange={(v) => handleCapContracts(data.year, 'q3_cap_price', v)}
                    required={formData.Cap_contracting_is_intended}
                    readonly={!formData.Cap_contracting_is_intended}
                    error={errors[`cap_contracts[${data.operating_year - 1}].q3_cap_price`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.q3_cap_strike_price.toString()}
                    onChange={(v) => handleCapContracts(data.year, 'q3_cap_strike_price', v)}
                    required={formData.Cap_contracting_is_intended}
                    readonly={!formData.Cap_contracting_is_intended}
                    error={errors[`cap_contracts[${data.operating_year - 1}].q3_cap_strike_price`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.q4_cap_volume.toString()}
                    onChange={(v) => handleCapContracts(data.year, 'q4_cap_volume', v)}
                    required={formData.Cap_contracting_is_intended}
                    readonly={!formData.Cap_contracting_is_intended}
                    error={errors[`cap_contracts[${data.operating_year - 1}].q4_cap_volume`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.q4_cap_price.toString()}
                    onChange={(v) => handleCapContracts(data.year, 'q4_cap_price', v)}
                    required={formData.Cap_contracting_is_intended}
                    readonly={!formData.Cap_contracting_is_intended}
                    error={errors[`cap_contracts[${data.operating_year - 1}].q4_cap_price`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.q4_cap_strike_price.toString()}
                    onChange={(v) => handleCapContracts(data.year, 'q4_cap_strike_price', v)}
                    required={formData.Cap_contracting_is_intended}
                    readonly={!formData.Cap_contracting_is_intended}
                    error={errors[`cap_contracts[${data.operating_year - 1}].q4_cap_strike_price`]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </div>
  )
}
