import {
  Button,
  Container,
  Grid,
  IHeader,
  Loader,
  Pagination,
  SearchBox,
  Table,
  TableCell,
  TableRow,
} from '@aurecon-creative-technologies/styleguide'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import ReportsActionButtons from 'src/components/ReportsActionButtons'
import { ReportsTableHeaders } from 'src/enums/ReportsEnum'
import {
  fetchReportsDataSelector,
  inProductedSecretState,
  reportsCurrentPageState,
  reportsDataState,
  reportsSearchQueryState,
  selectedProjectIdState,
} from 'src/stores/AppStore'
import Style from '../../styles/Reports.module.sass'
import { compareValues, SortOrder } from 'src/helpers/utils'
import { isRoleEmpty } from 'src/helpers/appRoles'
import { DEFAULTPAGINATIONDISPLAYPAGES } from 'src/config/constant'
import { appInsights } from 'src/api/AppInsights'
import { debounce } from 'lodash'
const MemoizedReportsActionButtons = React.memo(ReportsActionButtons)

interface SortConfig {
  key: string
  order: SortOrder
}
const ReportsList: React.FC = () => {
  useEffect(() => {
    if (appInsights) appInsights.trackPageView({ name: 'ReportList' })
  }, [])
  const navigate = useNavigate()
  const SecretState = useRecoilValue(inProductedSecretState)
  const projectId = useRecoilValue(selectedProjectIdState)
  const setReportsData = useSetRecoilState(reportsDataState)
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', order: 'asc' })
  const setSearchQuery = useSetRecoilState(reportsSearchQueryState)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useRecoilState(reportsCurrentPageState)
  const reportDataLoadable = useRecoilValueLoadable(fetchReportsDataSelector)
  const reportsData = useRecoilValueLoadable(reportsDataState)

  const handlePaginationClick = useCallback(
    (page: number) => {
      setCurrentPage(page === 0 ? 1 : page)
    },
    [setCurrentPage],
  )

  useEffect(() => {
    if (reportDataLoadable.state === 'hasValue' && reportDataLoadable.contents) {
      setReportsData(reportDataLoadable.contents)
    }
  }, [reportDataLoadable, setReportsData])

  const tableData = useMemo(
    () =>
      reportsData.state === 'hasValue' && reportsData.contents
        ? reportsData.contents.items.map((item) => ({
            id: item?.id,
            name: item?.name,
            preferred_scenario: item?.preferred_scenario?.name,
            created_at: new Date(item.created_at).toLocaleDateString(),
            updated_at: new Date(item.updated_at).toLocaleDateString(),
            report_file_path: item?.report_file_path,
          }))
        : [],
    [reportsData],
  )

  const sortedReports = useMemo(() => {
    const sorted = [...tableData].sort((a, b) => {
      const itemAValue = a[sortConfig.key]
      const itemBValue = b[sortConfig.key]

      return sortConfig.order === 'asc'
        ? compareValues(itemAValue, itemBValue, 'asc')
        : compareValues(itemAValue, itemBValue, 'desc')
    })

    return sorted
  }, [tableData, sortConfig])

  useEffect(() => {
    if (reportDataLoadable.state === 'loading') {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [reportDataLoadable.state])

  if (reportsData.state === 'hasError') {
    return <div>Error loading data</div>
  }

  const gotoGenerateReport = () => {
    navigate(`/dashboard/${projectId}/generatereport`)
  }

  const handleSearchClick = debounce((query: string) => {
    setSearchQuery(query)
  }, 300)

  const handleSort = (key: string) => {
    setSortConfig((prevSortConfig) => ({
      key,
      order: prevSortConfig.key === key && prevSortConfig.order === 'asc' ? 'desc' : 'asc',
    }))
  }

  const reportsTableHeaders: IHeader[] = [
    {
      label: ReportsTableHeaders.REPORT,
      sort: sortConfig.key === 'name' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('name'),
    },
    {
      label: ReportsTableHeaders.PREFEREDSCENARIOS,
      sort: sortConfig.key === 'preferred_scenario' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('preferred_scenario'),
    },
    {
      label: ReportsTableHeaders.CREATED_DATE,
      sort: sortConfig.key === 'created_at' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('created_at'),
    },
    {
      label: ReportsTableHeaders.UPDATED_DATE,
      sort: sortConfig.key === 'updated_at' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('updated_at'),
    },
    {
      label: ReportsTableHeaders.ACTION,
    },
  ]

  return (
    <Container fluid>
      <header className={Style.header}>
        <Container fluid cssClass={Style.paddingX}>
          <div className={Style.header_wrapper}>
            <h1 className={Style.titleHead}>Report</h1>
            {SecretState && !isRoleEmpty(SecretState.role) && (
              <Button
                type='custom'
                cssClass={Style.btnNewReport}
                label='Generate Report'
                onClick={gotoGenerateReport}
              />
            )}
          </div>
        </Container>
      </header>

      <section>
        <Container fluid cssClass={`${Style.paddingX} ${Style.pageBtm}`}>
          <section className={Style.search_box}>
            <Grid row>
              <Grid item xs={12} sm={6} md={12} lg={6} xl={3}>
                <SearchBox
                  placeholder='Search for Report'
                  onChange={(searchTerm) => handleSearchClick(searchTerm)}
                  onClear={() => handleSearchClick('')}
                />
              </Grid>
            </Grid>
          </section>
          <div className={Style.grid_container}>
            <div className={Style.overflowx}>
              <Table headers={reportsTableHeaders}>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan={reportsTableHeaders.length} style={{ textAlign: 'center' }}>
                      <div className={Style.loaderWrapper}>
                        <Loader />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {!isLoading && sortedReports?.length > 0
                  ? sortedReports.map((report) => (
                      <TableRow key={report.id}>
                        <TableCell cellClass={Style.tableDataPadding}>{report?.name}</TableCell>
                        <TableCell cellClass={Style.tableDataPadding}>{report.preferred_scenario}</TableCell>
                        <TableCell cellClass={Style.tableDataPadding}>{report.created_at.split('T')[0]}</TableCell>
                        <TableCell cellClass={Style.tableDataPadding}>{report.updated_at.split('T')[0]}</TableCell>
                        <TableCell cellClass={Style.tableDataPadding}>
                          <MemoizedReportsActionButtons
                            reportId={report['id']}
                            reportFilePath={report['report_file_path']}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : !isLoading && (
                      <TableRow>
                        <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
              </Table>
            </div>
          </div>
          <div className={Style.paginationCont}>
            <div className={Style.paginationButtons}>
              <Pagination
                page={currentPage}
                pageCount={reportsData.state === 'hasValue' ? reportsData.contents?.pages ?? 0 : 0}
                displayPages={DEFAULTPAGINATIONDISPLAYPAGES}
                onChange={handlePaginationClick}
                prevButtonTitle='Previous page'
                nextButtonTitle='Next page'
                cssClass={Style.paginatCont}
              />
            </div>
            <div className={Style.totalPageCount}>
              {currentPage} - {reportsData.state === 'hasValue' ? reportsData.contents?.pages : 0} of{' '}
              {reportsData.state === 'hasValue' ? reportsData.contents?.total : 0} Reports
            </div>
          </div>
        </Container>
      </section>
    </Container>
  )
}

export default ReportsList
