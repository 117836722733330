export const downloadAction = (data: Blob, fileName?: string) => {
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName || 'file.txt')
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}

export const convertJSONtoBlob = (obj: unknown) => {
  const str = JSON.stringify(obj)
  const bytes = new TextEncoder().encode(str)
  const blob = new Blob([bytes], {
    type: 'application/json;charset=utf-8',
  })

  return blob
}

export const base64ToBlob = (base64String: string, mimeType: string): Blob => {
  const bufferLength = 1024
  const byteCharacters = atob(base64String)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += bufferLength) {
    const slice = byteCharacters.slice(offset, offset + bufferLength)
    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: mimeType })
}

export const createFileFromBlob = (blob: Blob, fileName: string): File => {
  const file = new File([blob], fileName)
  return file
}
