import { Container, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import React from 'react'
import { ReportDetailResponeModal } from 'src/models/api/ReportDetailResponeModal'
import Style from '../../../styles/Reports.module.sass'

interface ViewCommercialAndMarketAssumptionsProps {
  formData: ReportDetailResponeModal
}

const ViewCommercialAndMarketAssemptions: React.FC<ViewCommercialAndMarketAssumptionsProps> = ({ formData }) => {
  return (
    <Container fluid>
      <div className={Style.tbl_container}>
        <Table headers={[{ label: 'Commercial and Market Assumptions' }, { label: 'Details', align: 'center' }]}>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Commercial Operations Date</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.commercial_operation_date.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Economic Life</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.economic_life.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>NEM region</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.nem_region.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Contingency FCAS registered capacity</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.contingency_fcas_registered_capacity.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Regulation FCAS registered capacity</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.regulation_fcas_registered_capacity.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Cap contract price</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.cap_contract_price.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Cap contract volume</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.cap_contract_volume.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Load Marginal Loss Factor</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.load_marginal_loss_factor.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Generation Marginal Loss Factor</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.generation_marginal_loss_factor.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Percentage of perfect correction factors</p>
              <ul>
                <li>
                  <p>Wholesale energy</p>
                </li>
                <li>
                  <p>Regulation FCAS</p>
                </li>
                <li>
                  <p>Contingency FCAS</p>
                </li>
              </ul>
            </TableCell>
            <TableCell align='center' cellClass={Style.tableDataPadding}>
              <p> {formData.report_detail?.pop_wholesale_correction_factor.toString()}</p>
              <p> {formData.report_detail?.pop_regulation_fcas_correction_factor.toString()}</p>
              <p> {formData.report_detail?.pop_contingency_fcas_correction_factor.toString()}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Capital Cost</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.capital_cost.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Average total O & M cost</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.average_total_o_and_m_cost.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>LGC prices</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.lgc_price.toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Outage rates</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding} align='center'>
              {formData.report_detail?.outage_rate.toString()}
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </Container>
  )
}

export default ViewCommercialAndMarketAssemptions
