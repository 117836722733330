import React, { useEffect, useState } from 'react'
import { Container, Table, TableCell, TableRow, FormInput } from '@aurecon-creative-technologies/styleguide'
import Style from '../../../styles/Reports.module.sass'
import { GenerateReportRequest, TechnicalAssumption } from 'src/models/api/GenerateReportRequestFormData'

interface TechnicalAssumptionsProps {
  projectLife: number
  firstYearOfCommercialOperation: number
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  error: Record<string, string>
  projectType: number | undefined
}

const TechnicalAssumptions: React.FC<TechnicalAssumptionsProps> = ({
  projectLife,
  firstYearOfCommercialOperation,
  error,
  setFormData,
  projectType,
}) => {
  const [rows, setRows] = useState<TechnicalAssumption[]>([])

  useEffect(() => {
    const newRows = Array.from({ length: projectLife }, (_, i) => ({
      year: firstYearOfCommercialOperation + i,
      operating_year: i + 1,
      energy_storage_retention: '0',
      round_trip_efficiency: '0',
      re_generation: '0',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      created_by: '',
      updated_by: '',
    }))
    setRows(newRows)
  }, [projectLife, firstYearOfCommercialOperation])

  const handleInputChange = (value: string, index: number, key: keyof TechnicalAssumption) => {
    const newRows = [...rows]
    newRows[index] = { ...newRows[index], [key]: value }
    setRows(newRows)

    setFormData((prevFormData) => ({
      ...prevFormData,
      report_detail: {
        ...prevFormData.report_detail,
        technical_assumptions: newRows,
      },
    }))
  }

  const getErrorMessage = (index: number, key: keyof TechnicalAssumption) => {
    return error[`report_detail.technical_assumptions.${index}.${key}`] || ''
  }

  return (
    <Container fluid>
      <div className={Style.tbl_container}>
        <Table
          headers={[
            { label: 'Year' },
            { label: 'Energy Storage Retention' },
            { label: 'Round Trip Efficiency' },
            ...(projectType !== 1 ? [{ label: 'RE generation (MWh)' }] : []),
          ]}
        >
          {rows.map((item, index) => (
            <TableRow key={item.year}>
              <TableCell cellClass={Style.tableDataPadding}>
                <p>{item.year}</p>
              </TableCell>
              <TableCell cellClass={Style.tableDataPadding}>
                <FormInput
                  type='number'
                  value={item.energy_storage_retention}
                  onChange={(e) => handleInputChange(e, index, 'energy_storage_retention')}
                  placeholder='Enter Energy Storage Retention'
                  error={getErrorMessage(index, 'energy_storage_retention')}
                />
              </TableCell>
              <TableCell cellClass={Style.tableDataPadding}>
                <FormInput
                  type='number'
                  value={item.round_trip_efficiency}
                  onChange={(e) => handleInputChange(e, index, 'round_trip_efficiency')}
                  placeholder='Enter Round Trip Efficiency'
                  error={getErrorMessage(index, 'round_trip_efficiency')}
                />
              </TableCell>
              {projectType !== 1 && (
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='number'
                    value={item.re_generation}
                    onChange={(e) => handleInputChange(e, index, 're_generation')}
                    placeholder='Enter RE generation (MWh)'
                    error={getErrorMessage(index, 're_generation')}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </Table>
      </div>
    </Container>
  )
}

export default TechnicalAssumptions
