import { Chart, ChartConfiguration, ScriptableContext, TooltipItem, registerables, LegendItem } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import React, { useEffect, useRef } from 'react'
import { PreferredScenarioDashboardColors, PreferredScenarioReportColors } from 'src/config/colors'

Chart.register(...registerables, ChartDataLabels)

interface PreferredScenarioResultsChartProps {
  cumulativeData: CumulativeDataItem[]
  pageType: 'dashboard' | 'report'
}

interface CumulativeDataItem {
  x: string
  y: [number, number]
}

const getChartConfig = (
  cumulativeData: CumulativeDataItem[],
  barColors: { positive: string; negative: string; total: string },
  pageType: 'dashboard' | 'report',
): ChartConfiguration<'bar'> => {
  return {
    type: 'bar',
    data: {
      labels: cumulativeData.map((item) => item.x),
      datasets: [
        {
          label: 'Waterfall',
          data: cumulativeData.map((item) => item.y),
          backgroundColor: (context: ScriptableContext<'bar'>) => {
            const index = context.dataIndex
            const rawValue = context.raw as number[]
            const value = rawValue[1] - rawValue[0]
            if (index === cumulativeData.length - 1 && pageType === 'report') {
              return barColors.total
            }
            return value > 0 ? barColors.positive : barColors.negative
          },
          borderColor: 'black',
          borderWidth: 1,
        },
      ],
    },
    options: {
      indexAxis: 'x',
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            color: '#7B8D70',
            font: {
              size: 10,
            },
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 100,
            color: '#7B8D70',
            font: {
              size: 12,
            },
          },
          title: {
            display: true,
            text: 'Net Present Value ($M)',
            font: {
              size: 12,
              weight: 500,
            },
            color: '#7B8D70',
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            generateLabels: () => {
              const labels: LegendItem[] = [
                {
                  text: 'Positive',
                  fillStyle: barColors.positive,
                  hidden: false,
                  lineCap: 'butt',
                  lineDash: [],
                  lineDashOffset: 0,
                  lineJoin: 'miter',
                  lineWidth: 0,
                  strokeStyle: barColors.positive,
                  pointStyle: 'circle',
                },
                {
                  text: 'Negative',
                  fillStyle: barColors.negative,
                  hidden: false,
                  lineCap: 'butt',
                  lineDash: [],
                  lineDashOffset: 0,
                  lineJoin: 'miter',
                  lineWidth: 0,
                  strokeStyle: barColors.negative,
                  pointStyle: 'circle',
                },
              ]

              if (pageType === 'report') {
                labels.push({
                  text: 'Total',
                  fillStyle: barColors.total,
                  hidden: false,
                  lineCap: 'butt',
                  lineDash: [],
                  lineDashOffset: 0,
                  lineJoin: 'miter',
                  lineWidth: 0,
                  strokeStyle: barColors.total,
                  pointStyle: 'circle',
                })
              }

              return labels
            },
          },
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem: TooltipItem<'bar'>) => {
              const rawValue = tooltipItem.raw as number[]
              const start = rawValue[0]
              const end = rawValue[1]
              return `${tooltipItem.label}: ${(end - start).toFixed(2)}`
            },
          },
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          formatter: (value) => {
            const displayValue = value[1] - value[0]
            return displayValue.toFixed(2)
          },
          color: 'black',
          font: {
            weight: 'bold',
          },
        },
      },
    },
  }
}

const createOrUpdateChart = (
  chartRef: React.RefObject<HTMLCanvasElement>,
  chartInstanceRef: React.MutableRefObject<Chart<'bar'> | null>,
  cumulativeData: CumulativeDataItem[],
  barColors: { positive: string; negative: string; total: string },
  pageType: 'dashboard' | 'report',
) => {
  const ctx = chartRef.current?.getContext('2d')
  if (!ctx) return

  if (chartInstanceRef.current) {
    chartInstanceRef.current.destroy()
  }

  const chartConfig = getChartConfig(cumulativeData, barColors, pageType)
  chartInstanceRef.current = new Chart(ctx, chartConfig)
}

const PreferredScenarioResultsChart: React.FC<PreferredScenarioResultsChartProps> = ({ cumulativeData, pageType }) => {
  const chartRef = useRef<HTMLCanvasElement>(null)
  const chartInstanceRef = useRef<Chart<'bar'> | null>(null)

  const barColors = pageType === 'dashboard' ? PreferredScenarioDashboardColors : PreferredScenarioReportColors

  useEffect(() => {
    createOrUpdateChart(chartRef, chartInstanceRef, cumulativeData, barColors, pageType)

    const currentChartInstance = chartInstanceRef.current
    return () => {
      if (currentChartInstance) {
        currentChartInstance.destroy()
      }
    }
  }, [cumulativeData, barColors, pageType])

  return (
    <div className='chart-container'>
      <canvas ref={chartRef} width={400} height={300} className='chart-canvas'></canvas>
    </div>
  )
}

export default PreferredScenarioResultsChart
