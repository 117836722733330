import {
  Container,
  DateInput,
  FormInput,
  Grid,
  Table,
  TableCell,
  TableRow,
} from '@aurecon-creative-technologies/styleguide'
import React from 'react'
import { GenerateReportRequest, ReportDetail } from 'src/models/api/GenerateReportRequestFormData'
import Style from '../../../styles/Reports.module.sass'

interface CommercialAndMarketAssumptionsProps {
  formData: GenerateReportRequest
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  error: Record<string, string>
}

const CommercialAndMarketAssumptions: React.FC<CommercialAndMarketAssumptionsProps> = ({
  formData,
  error,
  setFormData,
}) => {
  const handleInputChange = (value: string | number, key: keyof ReportDetail) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      report_detail: {
        ...prevFormData.report_detail,
        [key]: value,
      },
    }))
  }

  const handleDateChange = async (dates: { startDate: Date | null; endDate: Date | null }) => {
    //
    setFormData((prevFormData) => ({
      ...prevFormData,
      report_detail: {
        ...prevFormData.report_detail,
        commercial_operation_date: dates?.startDate ? new Date(dates?.startDate).toISOString() : '',
      },
    }))
  }

  const getErrorMessage = (key: keyof ReportDetail) => {
    return error[`report_detail.${key}`] || ''
  }
  return (
    <Container fluid>
      <div className={Style.tbl_container}>
        <Table headers={[{ label: 'Commercial and Market Assumptions' }, { label: 'Details' }]}>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Commercial Operations Date</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <DateInput
                dates={{
                  startDate: new Date(formData.report_detail.commercial_operation_date),
                  endDate: new Date(formData.report_detail.commercial_operation_date),
                }}
                onDateChange={handleDateChange}
                error={getErrorMessage('commercial_operation_date')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Economic Life</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='number'
                value={String(formData.report_detail.economic_life)}
                onChange={(e) => handleInputChange(e, 'economic_life')}
                placeholder='Enter Economic Life'
                error={getErrorMessage('economic_life')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>NEM region</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='text'
                value={formData.report_detail.nem_region}
                onChange={(e) => handleInputChange(e, 'nem_region')}
                placeholder='Enter NEM region'
                error={getErrorMessage('nem_region')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Contingency FCAS registered capacity</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='number'
                value={formData.report_detail.contingency_fcas_registered_capacity}
                onChange={(e) => handleInputChange(e, 'contingency_fcas_registered_capacity')}
                placeholder='Enter Contingency FCAS registered capacity'
                error={getErrorMessage('contingency_fcas_registered_capacity')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Regulation FCAS registered capacity</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='number'
                value={formData.report_detail.regulation_fcas_registered_capacity}
                onChange={(e) => handleInputChange(e, 'regulation_fcas_registered_capacity')}
                placeholder='Enter Regulation FCAS registered capacity'
                error={getErrorMessage('regulation_fcas_registered_capacity')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Cap contract price</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='number'
                value={formData.report_detail.cap_contract_price}
                onChange={(e) => handleInputChange(e, 'cap_contract_price')}
                placeholder='Enter Cap contract price'
                error={getErrorMessage('cap_contract_price')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Cap contract volume</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='number'
                value={formData.report_detail.cap_contract_volume}
                onChange={(e) => handleInputChange(e, 'cap_contract_volume')}
                placeholder='Enter Cap contract volume'
                error={getErrorMessage('cap_contract_volume')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Load Marginal Loss Factor</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='number'
                value={formData.report_detail.load_marginal_loss_factor}
                onChange={(e) => handleInputChange(e, 'load_marginal_loss_factor')}
                placeholder='Enter Load Marginal Loss Factor'
                error={getErrorMessage('load_marginal_loss_factor')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Generation Marginal Loss Factor</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='number'
                value={formData.report_detail.generation_marginal_loss_factor}
                onChange={(e) => handleInputChange(e, 'generation_marginal_loss_factor')}
                placeholder='Enter Generation Marginal Loss Factor'
                error={getErrorMessage('generation_marginal_loss_factor')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Percentage of perfect correction factors</p>
              <ul>
                <li>
                  <p>Wholesale energy</p>
                </li>
                <li>
                  <p>Regulation FCAS</p>
                </li>
                <li>
                  <p>Contingency FCAS</p>
                </li>
              </ul>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <Grid row gap={12}>
                <Grid item center cell>
                  <FormInput
                    type='number'
                    value={formData.report_detail.pop_wholesale_correction_factor}
                    onChange={(e) => handleInputChange(e, 'pop_wholesale_correction_factor')}
                    placeholder='Wholesale energy'
                    error={getErrorMessage('pop_wholesale_correction_factor')}
                  />
                  <FormInput
                    type='number'
                    value={formData.report_detail.pop_regulation_fcas_correction_factor}
                    onChange={(e) => handleInputChange(e, 'pop_regulation_fcas_correction_factor')}
                    placeholder='Regulation FCAS'
                    error={getErrorMessage('pop_regulation_fcas_correction_factor')}
                  />
                  <FormInput
                    type='number'
                    value={formData.report_detail.pop_contingency_fcas_correction_factor}
                    onChange={(e) => handleInputChange(e, 'pop_contingency_fcas_correction_factor')}
                    placeholder='Contingency FCAS'
                    error={getErrorMessage('pop_contingency_fcas_correction_factor')}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Capital Cost</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='number'
                value={formData.report_detail.capital_cost}
                onChange={(e) => handleInputChange(e, 'capital_cost')}
                placeholder='Contingency FCAS: Percent of Perfect r'
                error={getErrorMessage('capital_cost')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Average total O & M cost</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='number'
                value={formData.report_detail.average_total_o_and_m_cost}
                onChange={(e) => handleInputChange(e, 'average_total_o_and_m_cost')}
                placeholder='Average total O & M cost'
                error={getErrorMessage('average_total_o_and_m_cost')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>LGC prices</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='number'
                value={formData.report_detail.lgc_price}
                onChange={(e) => handleInputChange(e, 'lgc_price')}
                placeholder='LGC price'
                error={getErrorMessage('lgc_price')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
              <p>Outage rates</p>
            </TableCell>
            <TableCell cellClass={Style.tableDataPadding}>
              <FormInput
                type='number'
                value={formData.report_detail.outage_rate}
                onChange={(e) => handleInputChange(e, 'outage_rate')}
                placeholder='Outage rates'
                error={getErrorMessage('outage_rate')}
              />
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </Container>
  )
}

export default CommercialAndMarketAssumptions
