import {
  Button,
  Container,
  Grid,
  IHeader,
  IOverflowMenuItemProps,
  Loader,
  Modal,
  OverflowMenu,
  Pagination,
  Pill,
  SearchBox,
  Table,
  TableCell,
  TableRow,
  useModal,
  useToast,
} from '@aurecon-creative-technologies/styleguide'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import { downloadErrorLogFile, downloadSummaryFile } from 'src/api/ExecutionsService'
import { downloadExecutionSummaryFile } from 'src/api/ForecastService'
import { FAILEDCOLOR, IN_PROGRESSCOLOR, SUCCESSCOLOR } from 'src/config/colors'
import { DEFAULTPAGINATIONDISPLAYPAGES } from 'src/config/constant'
import { ExecutionTableHeaders, OverflowMenuItems } from 'src/enums/ExecutionEnum'
import { compareValues, SortOrder } from 'src/helpers/utils'
import {
  executionCurrentPageState,
  executionDataState,
  executionSearchQueryState,
  fetchExecutionDataSelector,
  inProductedSecretState,
  selectedProjectIdState,
} from 'src/stores/AppStore'
import Style from '../../styles/Execution.module.sass'
import ConfirmationExecutionModel from './ConfirmationExecutionModel'
import { isRoleEmpty } from 'src/helpers/appRoles'
import { debounce } from 'lodash'

const overFlowMenuItems: IOverflowMenuItemProps[] = [
  { id: '1', label: OverflowMenuItems.SEE_DETAILS },
  { id: '2', label: OverflowMenuItems.VIEW_IRR_NPV },
  { id: '3', label: OverflowMenuItems.DOWNLOAD_SUMMARY },
  { id: '4', label: OverflowMenuItems.DOWNLOAD_FORECAST },
  { id: '5', label: OverflowMenuItems.DOWNLOAD_ERROR_LOG },
]

const getStatusDisplayName = (status: string) => {
  switch (status) {
    case 'SUCCESSFUL':
      return 'Success'
    case 'FAILED':
      return 'Failed'
    default:
      return 'In Progress'
  }
}

const STATUS_COLOR: { [key: string]: string } = {
  Success: SUCCESSCOLOR,
  Failed: FAILEDCOLOR,
  'In Progress': IN_PROGRESSCOLOR,
}

interface SortConfig {
  key: string
  order: SortOrder
}

const ExecutionsList: React.FC = () => {
  useEffect(() => {
    if (appInsights) appInsights.trackPageView({ name: 'ExecutionsList' })
  }, [])
  const navigate = useNavigate()
  const showToast = useToast()
  const successLogToast = 'Error log file successfully downloaded'
  const successSummaryLogToast = 'Summary file successfully downloaded'
  const successDetailedOutputToast = 'Detailed output file successfully downloaded'
  const errorLogToast = 'Failed to download'
  const SecretState = useRecoilValue(inProductedSecretState)
  const [currentPage, setCurrentPage] = useRecoilState(executionCurrentPageState)
  const { isShowing, toggleModal } = useModal()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null)

  const projectId = useRecoilValue(selectedProjectIdState)
  const setSearchQuery = useSetRecoilState(executionSearchQueryState)
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', order: 'asc' })
  const setExecutionData = useSetRecoilState(executionDataState)
  const executionDataLoadable = useRecoilValueLoadable(fetchExecutionDataSelector)
  const executionData = useRecoilValueLoadable(executionDataState)

  const gotoDetail = useCallback(
    (scenario_id: number | undefined) => {
      navigate(`/dashboard/${projectId}/executiondetail/${scenario_id}`)
    },
    [navigate, projectId],
  )

  const gotoIRRNVP = useCallback(
    (selectedExecutionID: number | undefined) => {
      navigate(`/dashboard/${projectId}/executionirrnvp/${selectedExecutionID}`)
    },
    [navigate, projectId],
  )
  const handleSelectItem = (itemId: string, scenario_id?: number) => {
    switch (itemId) {
      case '1':
        gotoDetail(scenario_id)
        break
      case '2':
        gotoIRRNVP(scenario_id)
        break
      case '3':
        handleSummaryDownloadClick(scenario_id)
        break
      case '4':
        handleDownloadClick(scenario_id)
        break
      case '5':
        handleDownloadErrorLogClick(scenario_id)
        break
      default:
        break
    }
  }

  const handleResponseToast = (
    message: string,
    toastType: 'success' | 'error' | 'info' | 'warning',
    timeout: number,
  ) => {
    showToast.addToast({
      message: message,
      type: toastType,
      timeout: timeout,
    })
  }

  const handleDownloadClick = async (execution_scenario_id: number | undefined) => {
    try {
      if (execution_scenario_id) {
        const response = await downloadExecutionSummaryFile(execution_scenario_id, (progress) => {
          setDownloadProgress(progress)
        })
        if (response.success || response.status === 200 || response.status === 201) {
          handleResponseToast(successDetailedOutputToast, 'success', 3000)
        } else if (response.message === 'Internal Server Error' || response.status === 500) {
          handleResponseToast(errorLogToast + '\n' + response.message, 'error', 3000)
        } else {
          handleResponseToast(errorLogToast + '\n' + response.message, 'error', 3000)
        }
        setDownloadProgress(null)
      }
    } catch (error) {
      console.error('Failed to download detailed output file:', error, downloadProgress)
      setDownloadProgress(null)
    }
  }

  const handleSummaryDownloadClick = async (selectedExecutionID: number | undefined) => {
    try {
      if (selectedExecutionID) {
        const response = await downloadSummaryFile(selectedExecutionID, (progress) => {
          setDownloadProgress(progress)
        })
        if (response.success || response.status === 200 || response.status === 201) {
          handleResponseToast(successSummaryLogToast, 'success', 3000)
        } else if (response.message === 'Internal Server Error' || response.status === 500) {
          handleResponseToast(errorLogToast + '\n' + response.message, 'error', 3000)
        } else {
          handleResponseToast(errorLogToast + '\n' + response.message, 'error', 3000)
        }
        setDownloadProgress(null)
      }
    } catch (error) {
      console.error('Failed to download summary file:', error, downloadProgress)
      setDownloadProgress(null)
    }
  }

  const handleDownloadErrorLogClick = async (selectedExecutionID: number | undefined) => {
    try {
      if (selectedExecutionID) {
        const response = await downloadErrorLogFile(selectedExecutionID, (progress) => {
          setDownloadProgress(progress)
        })
        if (response.success || response.status === 200 || response.status === 201) {
          handleResponseToast(successLogToast, 'success', 3000)
        } else if (response.message === 'Internal Server Error' || response.status === 500) {
          handleResponseToast(errorLogToast + '\n' + response.message, 'error', 3000)
        } else {
          handleResponseToast(errorLogToast + '\n' + response.message, 'error', 3000)
        }
        setDownloadProgress(null)
      }
    } catch (error) {
      console.error('Failed to download error log:', error, downloadProgress)
      setDownloadProgress(null)
    }
  }

  const renderStatusCell = useCallback((status: string) => {
    const displayStatus = getStatusDisplayName(status)
    return (
      <Pill colour={5} style={{ backgroundColor: STATUS_COLOR[displayStatus] }} size='medium'>
        {displayStatus}
      </Pill>
    )
  }, [])

  const handlePaginationClick = useCallback(
    (page: number) => {
      setCurrentPage(page === 0 ? 1 : page)
    },
    [setCurrentPage],
  )

  useEffect(() => {
    if (executionDataLoadable.state === 'hasValue' && executionDataLoadable.contents) {
      setExecutionData(executionDataLoadable.contents)
    }
  }, [executionDataLoadable, setExecutionData])

  const tableData = useMemo(
    () =>
      executionData.state === 'hasValue' && executionData.contents
        ? executionData.contents.items.map((item) => ({
            id: item.id,
            forecastId: item.scenario.forecast_data.id,
            scenario: item.scenario.name,
            executionDate: new Date(item.created_at).toLocaleDateString(),
            executeBy: item.created_by,
            associatedFile: item.scenario.forecast_data.file_name,
            status: item.status.name,
          }))
        : [],
    [executionData],
  )

  const sortedExecutions = useMemo(() => {
    const sorted = [...tableData].sort((a, b) => {
      const itemAValue = a[sortConfig.key]
      const itemBValue = b[sortConfig.key]

      return sortConfig.order === 'asc'
        ? compareValues(itemAValue, itemBValue, 'asc')
        : compareValues(itemAValue, itemBValue, 'desc')
    })

    return sorted
  }, [tableData, sortConfig])

  useEffect(() => {
    if (executionDataLoadable.state === 'loading') {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [executionDataLoadable.state])

  if (executionDataLoadable.state === 'hasError') {
    return <div>Error loading data</div>
  }

  const handleSearchClick = debounce((query: string) => {
    setSearchQuery(query)
  }, 300)

  const getMenuItemsForStatus = (status: string): IOverflowMenuItemProps[] => {
    if (status === 'SUCCESSFUL') {
      return overFlowMenuItems.slice(0, -1)
    } else if (status === 'FAILED') {
      return [overFlowMenuItems[0], overFlowMenuItems[4]]
    } else {
      return [overFlowMenuItems[0]]
    }
  }

  const renderOverflowMenu = (scenario_id: number, forecastId: number, status: string) => {
    const menuItems = getMenuItemsForStatus(status)

    return (
      <OverflowMenu
        items={menuItems}
        size='extra small'
        icon='more_horiz'
        onSelectItem={(itemId) => handleSelectItem(itemId, scenario_id)}
        default
        cssClass={Style.iconOverflow}
      />
    )
  }

  const handleSort = (key: string) => {
    setSortConfig((prevSortConfig) => ({
      key,
      order: prevSortConfig.key === key && prevSortConfig.order === 'asc' ? 'desc' : 'asc',
    }))
  }

  const executionTableHeaders: IHeader[] = [
    {
      label: ExecutionTableHeaders.SCENARIOS,
      sort: sortConfig.key === 'scenario' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('scenario'),
    },
    {
      label: ExecutionTableHeaders.EXECUTION_DATE,
      sort: sortConfig.key === 'executionDate' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('executionDate'),
    },
    {
      label: ExecutionTableHeaders.EXECUTE_BY,
      sort: sortConfig.key === 'executeBy' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('executeBy'),
    },
    {
      label: ExecutionTableHeaders.ASSOCIATED_FILE,
      sort: sortConfig.key === 'associatedFile' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('associatedFile'),
    },
    {
      label: ExecutionTableHeaders.STATUS,
      sort: sortConfig.key === 'status' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('status'),
    },
    {
      label: ExecutionTableHeaders.ACTION,
    },
  ]

  return (
    <Container fluid>
      <header className={Style.header}>
        <Container fluid cssClass={Style.paddingX}>
          <div className={Style.header_wrapper}>
            <h1 className={Style.titleHead}>Execution</h1>
            {SecretState && !isRoleEmpty(SecretState.role) && (
              <Button type='primary' cssClass={Style.newExecBtn} label='New Execution' onClick={toggleModal} />
            )}
          </div>
        </Container>
      </header>

      <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        actions={[]}
        cssClass='modalBackground'
        shouldOverlayClose={false}
        isCloseButton={true}
        size={'medium'}
      >
        <ConfirmationExecutionModel onCancel={toggleModal} />
      </Modal>
      <div className={Style.marginBtm}>
        <section>
          <Container fluid cssClass={Style.paddingX}>
            <section className={Style.search_box}>
              <Grid row>
                <Grid item xs={12} sm={6} md={12} lg={6} xl={3}>
                  <SearchBox
                    placeholder='Search by Scenarios'
                    onChange={(searchTerm) => handleSearchClick(searchTerm)}
                    onClear={() => handleSearchClick('')}
                  />
                </Grid>
              </Grid>
            </section>
            <div className={Style.grid_container}>
              <div className={Style.overflowx}>
                <Table cssClass={Style.tableAlignment} headers={executionTableHeaders}>
                  {isLoading && (
                    <TableRow>
                      <TableCell colSpan={executionTableHeaders.length} style={{ textAlign: 'center' }}>
                        <div className={Style.loaderWrapper}>
                          <Loader />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                  {!isLoading && sortedExecutions?.length > 0
                    ? sortedExecutions.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.scenario}</TableCell>
                          <TableCell>{row.executionDate}</TableCell>
                          <TableCell>{row.executeBy}</TableCell>
                          <TableCell>{row.associatedFile}</TableCell>
                          <TableCell>{renderStatusCell(row.status)}</TableCell>
                          <TableCell>
                            <div className={Style.btn_action}>
                              {renderOverflowMenu(row.id, row.forecastId, row.status)}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    : !isLoading && (
                        <TableRow>
                          <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                            No records found
                          </TableCell>
                        </TableRow>
                      )}
                </Table>
              </div>
            </div>
            <div className={Style.paginationCont}>
              <div className={Style.paginationButtons}>
                <Pagination
                  page={currentPage}
                  pageCount={executionData.contents?.pages || 0}
                  displayPages={DEFAULTPAGINATIONDISPLAYPAGES}
                  onChange={handlePaginationClick}
                  prevButtonTitle='Previous page'
                  nextButtonTitle='Next page'
                  cssClass={Style.paginatCont}
                />
              </div>
              <div className={Style.totalPageCount}>
                {currentPage} - {executionData.contents?.pages} of {executionData.contents?.total} Executions
              </div>
            </div>
          </Container>
        </section>
      </div>
    </Container>
  )
}

export default ExecutionsList
