import React from 'react'
import { CommercialPerformanceDataTableHeadersLabels } from 'src/enums/ReportsEnum'
import { Scenario } from 'src/models/api/ReportMappingResponseModel'
import CommercialPerformanceDataTable from './CommercialPerformanceDataTable'

interface CommercialPerformanceTableProps {
  scenario: Scenario[]
}

const CommercialPerformanceTable: React.FC<CommercialPerformanceTableProps> = ({ scenario }) => {
  const commercialPerformanceData = scenario.map((scn) => {
    const total_net_revenue = scn?.calculation_data?.['total_net_revenue']
    const net_present_value = scn?.calculation_data?.net_npv
    const internal_rate_of_return = scn?.calculation_data?.irr_percentage

    return [
      { label: 'Total net revenue', value: total_net_revenue },
      { label: 'Net present value', value: net_present_value },
      { label: 'Internal Rate of Return', value: internal_rate_of_return },
    ]
  })

  const labels = CommercialPerformanceDataTableHeadersLabels

  return (
    <CommercialPerformanceDataTable
      headers={['Commercial Performance', ...scenario.map((_, index) => `Scenario ${index + 1}`)]}
      data={commercialPerformanceData}
      labels={labels}
    />
  )
}

export default CommercialPerformanceTable
