import { Button, Container, FormInput, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import React, { useCallback, useEffect, useState } from 'react'
import {
  AdditionalField,
  GenerateReportRequest,
  ReportScenarioMapping,
} from 'src/models/api/GenerateReportRequestFormData'
import { Scenario } from 'src/models/api/ReportMappingResponseModel'
import Style from '../../../styles/Reports.module.sass'

interface ScenarioSummaryTableProps {
  scenario: Scenario[]
  formData: GenerateReportRequest
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  error: Record<string, string>
}

const getCoupledTypeLabel = (coupledType: string | null): string | null => {
  if (coupledType === '1') {
    return 'AC Coupled'
  } else if (coupledType === '2') {
    return 'DC Coupled'
  }
  return null
}

const getHybridGenerationTypeLabel = (hybridGenerationType: string | null): string | null => {
  if (hybridGenerationType === '1') {
    return 'Wind'
  } else if (hybridGenerationType === '2') {
    return 'Solar'
  }
  return null
}

const getErrorMessage = (path: string, errors: Record<string, string>): string => {
  return errors[path] || ''
}

const ScenarioSummaryTable: React.FC<ScenarioSummaryTableProps> = ({ scenario, error, formData, setFormData }) => {
  const [editingLabel, setEditingLabel] = useState(false)
  const [labelValue, setLabelValue] = useState('Other')

  const updateFormDataWithScenarios = useCallback(() => {
    const updatedScenarioMapping = scenario.map((scn, index) => {
      const currentMapping = formData?.report_detail.report_scenario_mapping[index] || {}
      const otherField = currentMapping.additional_fields?.find((field) => field.name === 'other')
      const otherFieldValue = otherField ? otherField.value : ''
      const coupledType = scn?.scenario?.scenario_detail?.coupled_type
      const hybridGenerationType = scn?.scenario?.scenario_detail?.coupled_type

      return {
        ...currentMapping,
        scenario_id: scn?.calculation_data?.scenario_id,
        rated_charge_power: scn?.scenario?.scenario_detail?.rated_charge_power,
        rated_discharge_power: scn?.scenario?.scenario_detail?.rated_charge_power,
        charge_power: scn?.scenario?.scenario_detail?.rated_discharge_power.toString(),
        discharge_power: scn?.scenario?.scenario_detail?.rated_charge_power.toString(),
        bol_storage_capacity: scn?.scenario?.scenario_detail?.bol_storage_capacity.toString(),
        internal_rate_of_return: (scn?.calculation_data.irr_percentage ?? 0).toString(),
        net_present_value: scn?.calculation_data?.net_npv.toString(),
        total_net_revenue: scn?.scenario?.scenario_detail?.inertia_contract.toString() || '',
        cap_contract: scn?.scenario?.scenario_detail?.is_cap_contract_intended
          ? currentMapping?.cap_contract || ''
          : '0',
        network_support_service: scn?.scenario?.scenario_detail?.is_network_services_intended
          ? currentMapping?.network_support_service || '0'
          : '0',
        other: otherFieldValue,
        additional_fields: currentMapping?.additional_fields || [],
        hybrid_coupled_type: getCoupledTypeLabel(coupledType),
        re_generator_nameplate_rating: getHybridGenerationTypeLabel(hybridGenerationType),
      }
    })

    setFormData((prevFormData) => {
      if (
        JSON.stringify(prevFormData?.report_detail?.report_scenario_mapping) !== JSON.stringify(updatedScenarioMapping)
      ) {
        return {
          ...prevFormData,
          report_detail: {
            ...prevFormData?.report_detail,
            report_scenario_mapping: updatedScenarioMapping,
          },
        }
      }
      return prevFormData
    })
  }, [formData?.report_detail?.report_scenario_mapping, scenario, setFormData])

  useEffect(() => {
    updateFormDataWithScenarios()
  }, [scenario, updateFormDataWithScenarios])

  const handleInputChange = (e: string, name: string, index: number) => {
    const value = e
    setFormData((prevFormData) => {
      const updatedScenarioMapping = [...prevFormData.report_detail.report_scenario_mapping]
      const currentMapping = updatedScenarioMapping[index] || {}

      const updatedMapping = {
        ...currentMapping,
        [name]: value,
      }

      if (name === 'other') {
        const existingFieldIndex = updatedMapping.additional_fields?.findIndex((field) => field.name === 'other')
        const updatedAdditionalFields = [...(updatedMapping.additional_fields || [])]
        if (existingFieldIndex !== undefined && existingFieldIndex !== -1) {
          updatedAdditionalFields[existingFieldIndex] = {
            ...updatedAdditionalFields[existingFieldIndex],
            value: value,
          }
        } else {
          updatedAdditionalFields.push({
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            created_by: '',
            updated_by: '',
            name: 'other',
            value: value,
            slug_name: 'other',
          })
        }
        updatedMapping.additional_fields = updatedAdditionalFields
      }
      updatedScenarioMapping[index] = updatedMapping
      return {
        ...prevFormData,
        report_detail: {
          ...prevFormData.report_detail,
          report_scenario_mapping: updatedScenarioMapping,
        },
      }
    })
  }

  const updateAdditionalFields = (
    additionalFields: Array<AdditionalField>,
    newLabel: string,
  ): Array<AdditionalField> => {
    return additionalFields.map((field) => {
      if (field && typeof field === 'object' && 'name' in field) {
        return field.name === 'other' ? { ...field, name: newLabel } : field
      }
      return field
    })
  }

  const updateScenarioMapping = (mapping: ReportScenarioMapping[], newLabel: string): ReportScenarioMapping[] => {
    return mapping.map((mapping) => {
      const additionalFields: Array<AdditionalField> = mapping.additional_fields || []
      const updatedAdditionalFields = updateAdditionalFields(additionalFields, newLabel)
      return { ...mapping, additional_fields: updatedAdditionalFields }
    })
  }

  const handleLabelChange = (e: string) => {
    const newLabel = e
    setLabelValue(newLabel)
    setFormData((prevFormData) => {
      const updatedScenarioMapping = updateScenarioMapping(prevFormData.report_detail.report_scenario_mapping, newLabel)
      return {
        ...prevFormData,
        report_detail: {
          ...prevFormData.report_detail,
          report_scenario_mapping: updatedScenarioMapping,
        },
      }
    })
  }

  const handleLabelBlur = () => {
    setEditingLabel(false)
  }

  const handleLabelClick = (label: string) => {
    if (label === labelValue) {
      setEditingLabel(true)
    }
  }

  const renderInputField = (value: string, name: string, index: number, disabled?: boolean) => {
    const errorPath = `report_detail.report_scenario_mapping[${index}].${name}`
    const errorMessage = getErrorMessage(errorPath, error)

    return (
      <FormInput
        value={value}
        type='number'
        onChange={(e) => handleInputChange(e, name, index)}
        disabled={disabled}
        error={errorMessage}
      />
    )
  }

  const renderTextInputField = (value: string, name: string, index: number, disabled?: boolean) => {
    const errorPath = `report_detail.report_scenario_mapping[${index}].${name}`
    const errorMessage = getErrorMessage(errorPath, error)

    return (
      <FormInput
        value={value}
        type='text'
        onChange={(e) => handleInputChange(e, name, index)}
        disabled={disabled}
        error={errorMessage}
      />
    )
  }

  const scenarioSummaryData = scenario.map((scn, index) => {
    const currentMapping = formData?.report_detail?.report_scenario_mapping[index] || {}
    const otherField = currentMapping.additional_fields?.find((field) => field?.name === 'other')
    const otherFieldValue = otherField ? otherField.value : '0'

    return [
      { label: 'Discharge Power', value: scn?.scenario?.scenario_detail?.rated_discharge_power },
      { label: 'Charge Power', value: scn?.scenario?.scenario_detail?.rated_charge_power },
      { label: 'BOL Storage Capacity', value: scn?.scenario?.scenario_detail?.bol_storage_capacity },
      {
        label: 'Cap contract scenario',
        value: scn?.scenario?.scenario_detail?.is_cap_contract_intended
          ? renderTextInputField(currentMapping.cap_contract || '0', 'cap_contract', index)
          : currentMapping.cap_contract || '0',
      },
      {
        label: 'Network Support Service Scenario',
        value: scn?.scenario?.scenario_detail?.is_network_services_intended
          ? renderInputField(currentMapping.network_support_service || '0', 'network_support_service', index)
          : currentMapping.network_support_service || '0',
      },
      {
        label: labelValue,
        value: renderInputField(otherFieldValue, 'other', index),
      },
    ]
  })

  const labels = Array.from(new Set(scenarioSummaryData.flat().map((item) => item?.label)))

  const renderLabel = (label: string) => {
    return label === labelValue && editingLabel ? (
      <FormInput value={labelValue} onChange={(e) => handleLabelChange(e)} onBlur={handleLabelBlur} />
    ) : (
      <Button type='text' onClick={() => handleLabelClick(label)} label={label} cssClass={Style.summaryLabel} />
    )
  }

  return (
    <Container fluid>
      <div className={Style.tbl_container}>
        <Table
          headers={[
            { label: 'Scenario Summary' },
            ...scenario.map((scenariObj) => ({
              label: scenariObj.scenario?.name || 'Scenario name',
            })),
          ]}
        >
          {labels.map((label) => (
            <TableRow key={label}>
              <TableCell>{renderLabel(label)}</TableCell>
              {scenarioSummaryData.map((data, index) => (
                <TableCell cellClass={Style.tableDataPadding} key={`${data[index].label}-${index}`}>
                  {data.find((item) => item.label === label)?.value || '-'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </Table>
      </div>
    </Container>
  )
}

export default ScenarioSummaryTable
