import React, { useEffect, useState, useCallback } from 'react'
import { ChartConfiguration } from 'chart.js/auto'
import { FcasForPreferredDay } from 'src/models/api/ReportMappingResponseModel'
import SampleDayFCASPriceChart from './SampleDayFCASPriceChart'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { updateChartDataInForm } from 'src/api/ReportService'

interface SampleDayFCASPriceChartProcessorProps {
  data: FcasForPreferredDay[] | null | undefined
  sectionId: string
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
}

const keys: Array<keyof FcasForPreferredDay> = [
  'adjusted_reg_raise_rrp',
  'adjusted_reg_lower_rrp',
  'r6_rrp_adjusted',
  'r60_rrp_adjusted',
  'r5_rrp_adjusted',
  'l6_rrp_adjusted',
  'l60_rrp_adjusted',
  'l5_rrp_adjusted',
]

const labelsForKeys = ['Reg Raise', 'Reg Lower', 'R6', 'R60', 'R5', 'L6', 'L60', 'L5']

const ProcessSampleDayFCASPriceData: React.FC<SampleDayFCASPriceChartProcessorProps> = ({
  data,
  sectionId,
  setFormData,
}) => {
  const [chartData, setChartData] = useState<ChartConfiguration<'line'>['data']>({
    labels: [],
    datasets: [],
  })

  useEffect(() => {
    if (!data) return

    const labels = data.map((item) => {
      const dateTime = new Date(item.datetime)
      const hours = dateTime.getHours()
      return `${hours.toString().padStart(2, '')}`
    })

    const displayLabels = labels.map((label, index) => {
      const dateTime = new Date(data[index].datetime)
      const hours = dateTime.getHours()
      return hours % 2 === 0 && dateTime.getMinutes() === 0 ? label : ''
    })

    const datasets = keys.map((key, index) => ({
      label: labelsForKeys[index],
      data: data.map((item) => Number(item[key]) || 0),
      fill: false,
      tension: 0.4,
    }))

    const newChartData: ChartConfiguration<'line'>['data'] = {
      labels: displayLabels,
      datasets: datasets,
    }

    setChartData(newChartData)
  }, [data])

  const updateFormData = useCallback(() => {
    updateChartDataInForm(setFormData, sectionId, 'chart3', chartData)
  }, [chartData, sectionId, setFormData])

  useEffect(() => {
    updateFormData()
  }, [updateFormData])

  return (
    <div>
      <SampleDayFCASPriceChart data={chartData} />
    </div>
  )
}

export default ProcessSampleDayFCASPriceData
