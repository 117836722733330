import { NpvChart } from 'src/models/api/ReportMappingResponseModel'

export interface CumulativeDataItem {
  x: string
  y: [number, number]
}

export const labelMapping: { [key: string]: string } = {
  capex_npv: 'Capex',
  ltsa_npv: 'LTSA',
  warranty_npv: 'EXTENDED WARRANTIES',
  lgc_npv: 'LGC',
  tuos_npv: 'TUOS',
  regulation_fcas_npv: 'REG FCAS',
  contracted_service_npv: 'CAP CONTRACTS',
  contingency_fcas_npv: 'CONTINGENCY FCAS',
  energy_market_npv: 'WHOLESALE MARKET',
  total: 'TOTAL',
  inertia_npv: 'INERTIA CONTRACTS',
}

export const getCumulativeData = (data: NpvChart): CumulativeDataItem[] => {
  const cumulativeData: CumulativeDataItem[] = []
  let cumulativeSum = 0.0

  Object.keys(data).forEach((key) => {
    if (key !== 'inertia_npv' && labelMapping[key] && typeof data[key] === 'number') {
      const sumOfItem = data[key] as number
      cumulativeData.push({
        x: labelMapping[key],
        y: [cumulativeSum, cumulativeSum + sumOfItem],
      })
      cumulativeSum += sumOfItem
    }
  })

  if (!isNaN(Number(data.inertia_npv))) {
    const sumOfInertia = Number(data.inertia_npv)
    cumulativeData.push({
      x: labelMapping['inertia_npv'],
      y: [cumulativeSum, cumulativeSum + sumOfInertia],
    })
    cumulativeSum += sumOfInertia
  }

  return cumulativeData
}

export const getCumulativeReportData = (data: NpvChart): CumulativeDataItem[] => {
  const cumulativeReportData: CumulativeDataItem[] = []
  let cumulativeReportSum = 0.0

  Object.keys(data).forEach((key) => {
    if (key !== 'inertia_npv' && labelMapping[key] && !isNaN(Number(data[key]))) {
      const sumOfItem = Number(data[key])
      cumulativeReportData.push({
        x: labelMapping[key],
        y: [cumulativeReportSum, cumulativeReportSum + sumOfItem],
      })
      cumulativeReportSum += sumOfItem
    }
  })

  if (!isNaN(Number(data.inertia_npv))) {
    const sumOfInertia = Number(data.inertia_npv)
    cumulativeReportData.push({
      x: labelMapping['inertia_npv'],
      y: [cumulativeReportSum, cumulativeReportSum + sumOfInertia],
    })
    cumulativeReportSum += sumOfInertia
  }

  const filteredData = cumulativeReportData.filter((item) => item.x !== labelMapping['total'])

  filteredData.push({
    x: labelMapping['total'],
    y: [0, cumulativeReportSum],
  })

  return filteredData
}
