import {
  Grid,
  Checkbox,
  Accordion,
  AccordionPanel,
  FormInput,
  Table,
  TableRow,
  TableCell,
} from '@aurecon-creative-technologies/styleguide'
import { useCallback, useEffect, useState } from 'react'
import Style from '../../styles/CreateScenario.module.sass'
import { NetworkSupportServicesInterface, ScenarioFormData } from 'src/models/ScenarioFormData'
import { InertiaContract, NonMarket, SipsContract, SrasContract, VcasContract } from 'src/enums/ScenarioEnums'
import { getCurrentDateISOString } from 'src/helpers/utils'

const inertiaContractHeaders = [
  { label: InertiaContract.OPERATING_YEAR },
  { label: InertiaContract.CONTRACTED_INERTIA_VALUE },
  { label: InertiaContract.CONTRACTED_INERTIA_CONTRACT_PRICE },
]

const nonMarketHeaders = [
  { label: NonMarket.OPERATING_YEAR },
  { label: NonMarket.DISCHARGE_POWER_RESERVATION },
  { label: NonMarket.CHARGE_POWER_RESERVATION },
  { label: NonMarket.ENERGY_RESERVATION },
]

const sipsContractHeaders = [
  { label: SipsContract.OPERATING_YEAR },
  { label: SipsContract.SIPS_RESERVED_POWER_CAPACITY },
  { label: SipsContract.SIPS_RESERVED_ENERGY_STORAGE_CAPACITY },
  { label: SipsContract.SIPS_CONTRACT_VALUE },
]

const srasContractHeaders = [
  { label: SrasContract.OPERATING_YEAR },
  { label: SrasContract.SRAS_RESERVED_ENERGY_STORAGE_CAPACITY },
  { label: SrasContract.SRAS_CONTRACT_VALUE },
]

const vcasContractHeaders = [
  { label: VcasContract.OPERATING_YEAR },
  { label: VcasContract.REAL_POWER_CAPABILITY_VCAS },
  { label: VcasContract.VCAS_CONTRACT_VALUE },
]

interface Step4Props {
  formData: ScenarioFormData
  setFormData: React.Dispatch<React.SetStateAction<ScenarioFormData>>
  errors: Record<string, string>
}

export const Step4: React.FC<Step4Props> = ({ formData, setFormData, errors }) => {
  const [selected, setSelected] = useState<string[]>(['p1', 'p2', 'p3', 'p4', 'p5'])

  const togglePanel = (panel: string) => {
    setSelected((sel) => (sel.includes(panel) ? sel.filter((p) => p !== panel) : [...sel, panel]))
  }

  const handleFieldChange = (name: string, value: string | number | boolean) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleContractChange = (year: number, field: keyof NetworkSupportServicesInterface, value: string | number) => {
    setFormData((prevData) => ({
      ...prevData,
      network_support_services: prevData.network_support_services.map((service) =>
        service.year === year ? { ...service, [field]: value } : service,
      ),
    }))
  }

  const addOrRemoveNetwork = useCallback(() => {
    const numberOfObjectsToAdd = Number(formData.project_life_years) + 1
    const currentNetworkServicesLength = formData.network_support_services.length

    if (!isNaN(numberOfObjectsToAdd)) {
      const updatedNetworkCollections = formData.network_support_services.map((factor, index) => {
        const newYear = Number(formData.first_year_of_commercial_operation) + index
        return {
          ...factor,
          year: newYear,
        }
      })
      for (let i = currentNetworkServicesLength; i < numberOfObjectsToAdd; i++) {
        const newYear = Number(formData.first_year_of_commercial_operation) + i
        const newNetworkObj: NetworkSupportServicesInterface = {
          created_at: getCurrentDateISOString(),
          updated_at: getCurrentDateISOString(),
          created_by: getCurrentDateISOString(),
          updated_by: getCurrentDateISOString(),
          operating_year: i,
          year: newYear,
          contracted_inertia_value: '0',
          contracted_inertia_contract_price: '0',
          sips_reserved_power_capacity: '0',
          sips_reserved_energy_storage_capacity: '0',
          sips_contract_value: '0',
          sras_reserved_energy_storage_capacity: '0',
          sras_contract_value: '0',
          vcas_real_power_capability: '0',
          vcas_contract_value: '0',
          discharge_power_res_non_market_service: '0',
          charge_power_res_non_market_service: '0',
          energy_res_non_market_service: '0',
        }
        updatedNetworkCollections.push(newNetworkObj)
      }

      const shouldUpdate =
        updatedNetworkCollections.length !== formData.network_support_services.length ||
        updatedNetworkCollections.some(
          (factor, index) => factor.year !== formData.network_support_services[index]?.year,
        )

      if (shouldUpdate) {
        setFormData((prevData) => ({
          ...prevData,
          network_support_services: updatedNetworkCollections.slice(0, numberOfObjectsToAdd),
        }))
      }
    }
  }, [
    formData.project_life_years,
    formData.network_support_services,
    formData.first_year_of_commercial_operation,
    setFormData,
  ])

  useEffect(() => {
    addOrRemoveNetwork()
  }, [addOrRemoveNetwork])

  return (
    <div className='mainContainerBlock'>
      <h2>Network Support Services</h2>
      <Grid row gap={12}>
        <Grid item lg={12}>
          <Checkbox
            label='Any network support services are intended'
            checked={formData.network_support_services_intended}
            onChange={(e: boolean) => handleFieldChange('network_support_services_intended', e)}
          />
        </Grid>
        <Grid item lg={12}>
          <div className={Style.error}>
            {errors['Inertia_contract'] ||
              errors['is_sips_contract'] ||
              errors['is_sras_contract'] ||
              errors['is_vcas_contract'] ||
              errors['is_non_market_service']}
          </div>
        </Grid>
      </Grid>
      <Grid row gap={12}>
        <Grid item lg={12}>
          <Accordion onPanelToggle={togglePanel} activePanelIds={selected} verticalPanelGap='20px'>
            <AccordionPanel
              panelId='p1'
              label={
                <div className={Style.p1Main}>
                  <h3 className={Style.p1Head}>
                    <Checkbox
                      label='Inertia contract'
                      disabled={!formData.network_support_services_intended}
                      checked={formData.Inertia_contract}
                      onChange={(e: boolean) => handleFieldChange('Inertia_contract', e)}
                    />
                  </h3>
                  <div className={Style.p1Note}>
                    Note: Leave inertia contract value as $0 if model to be run to value inertia service
                  </div>
                </div>
              }
            >
              <Grid row gap={12}>
                <Grid item lg={6}>
                  <FormInput
                    type='number'
                    label='Selected Inertia Constant (MWs/MVA)'
                    value={formData.selected_inertia_constant.toString()}
                    onChange={(v) => handleFieldChange('selected_inertia_constant', v)}
                    readonly={!formData.Inertia_contract}
                    required={formData.Inertia_contract}
                    error={errors.selected_inertia_constant}
                  />
                </Grid>
                <Grid item lg={6}>
                  <FormInput
                    type='number'
                    label='RoCoF design criteria (Hz/s)'
                    value={formData.rocof_design_criteria.toString()}
                    onChange={(v) => handleFieldChange('rocof_design_criteria', v)}
                    readonly={!formData.Inertia_contract}
                    required={formData.Inertia_contract}
                    error={errors.rocof_design_criteria}
                  />
                </Grid>
              </Grid>
              <Grid row gap={12} cssClass={Style.p10}>
                <Table headers={inertiaContractHeaders}>
                  {formData.network_support_services.map((data) => (
                    <TableRow key={data.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          readonly={!formData.Inertia_contract}
                          value={data.contracted_inertia_value}
                          onChange={(v) => handleContractChange(data.year, 'contracted_inertia_value', v)}
                          error={
                            errors[`network_support_services[${data.operating_year - 1}].contracted_inertia_value`]
                          }
                        />
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          readonly={!formData.Inertia_contract}
                          value={data.contracted_inertia_contract_price}
                          onChange={(v) => handleContractChange(data.year, 'contracted_inertia_contract_price', v)}
                          error={
                            errors[
                              `network_support_services[${data.operating_year - 1}].contracted_inertia_contract_price`
                            ]
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </AccordionPanel>
            <AccordionPanel
              panelId='p2'
              label={
                <div className={Style.p1Main}>
                  <h3 className={Style.p1Head}>
                    <Checkbox
                      label='Non-Market Service Reservations'
                      disabled={!formData.network_support_services_intended}
                      checked={formData.is_non_market_service}
                      onChange={(e: boolean) => handleFieldChange('is_non_market_service', e)}
                    />
                  </h3>
                </div>
              }
            >
              <Grid row gap={12} cssClass={Style.p10}>
                <Table headers={nonMarketHeaders}>
                  {formData.network_support_services.map((data) => (
                    <TableRow key={data.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          readonly={!formData.is_non_market_service}
                          value={data.discharge_power_res_non_market_service.toString()}
                          onChange={(v) => handleContractChange(data.year, 'discharge_power_res_non_market_service', v)}
                          error={
                            errors[
                              `network_support_services[${
                                data.operating_year - 1
                              }].discharge_power_res_non_market_service`
                            ]
                          }
                        />
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          readonly={!formData.is_non_market_service}
                          value={data.charge_power_res_non_market_service.toString()}
                          onChange={(v) => handleContractChange(data.year, 'charge_power_res_non_market_service', v)}
                          error={
                            errors[
                              `network_support_services[${data.operating_year - 1}].charge_power_res_non_market_service`
                            ]
                          }
                        />
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          readonly={!formData.is_non_market_service}
                          value={data.energy_res_non_market_service.toString()}
                          onChange={(v) => handleContractChange(data.year, 'energy_res_non_market_service', v)}
                          error={
                            errors[`network_support_services[${data.operating_year - 1}].energy_res_non_market_service`]
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </AccordionPanel>
            <AccordionPanel
              panelId='p3'
              label={
                <div className={Style.p1Main}>
                  <h3 className={Style.p1Head}>
                    <Checkbox
                      label='SIPS Contract'
                      disabled={!formData.network_support_services_intended}
                      checked={formData.is_sips_contract}
                      onChange={(e: boolean) => handleFieldChange('is_sips_contract', e)}
                    />
                  </h3>
                  <div className={Style.p1Note}>
                    Note: Leave SIPS contract value as $0 if model to be run to value SIPS service
                  </div>
                </div>
              }
            >
              <Grid row gap={12} cssClass={Style.p10}>
                <Table headers={sipsContractHeaders}>
                  {formData.network_support_services.map((data) => (
                    <TableRow key={data.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          readonly={!formData.is_sips_contract}
                          value={data.sips_reserved_power_capacity.toString()}
                          onChange={(v) => handleContractChange(data.year, 'sips_reserved_power_capacity', v)}
                          error={
                            errors[`network_support_services[${data.operating_year - 1}].sips_reserved_power_capacity`]
                          }
                        />
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          readonly={!formData.is_sips_contract}
                          value={data.sips_reserved_energy_storage_capacity.toString()}
                          onChange={(v) => handleContractChange(data.year, 'sips_reserved_energy_storage_capacity', v)}
                          error={
                            errors[
                              `network_support_services[${
                                data.operating_year - 1
                              }].sips_reserved_energy_storage_capacity`
                            ]
                          }
                        />
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          readonly={!formData.is_sips_contract}
                          value={data.sips_contract_value.toString()}
                          onChange={(v) => handleContractChange(data.year, 'sips_contract_value', v)}
                          error={errors[`network_support_services[${data.operating_year - 1}].sips_contract_value`]}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </AccordionPanel>
            <AccordionPanel
              panelId='p4'
              label={
                <div className={Style.p1Main}>
                  <h3 className={Style.p1Head}>
                    <Checkbox
                      label='SRAS Contract'
                      disabled={!formData.network_support_services_intended}
                      checked={formData.is_sras_contract}
                      onChange={(e: boolean) => handleFieldChange('is_sras_contract', e)}
                    />
                  </h3>
                  <div className={Style.p1Note}>
                    Note: Leave SRAS contract value as $0 if model to be run to value SRAS service
                  </div>
                </div>
              }
            >
              <Grid row gap={12} cssClass={Style.p10}>
                <Table headers={srasContractHeaders}>
                  {formData.network_support_services.map((data) => (
                    <TableRow key={data.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          readonly={!formData.is_sras_contract}
                          value={data.sras_reserved_energy_storage_capacity.toString()}
                          onChange={(v) => handleContractChange(data.year, 'sras_reserved_energy_storage_capacity', v)}
                          error={
                            errors[
                              `network_support_services[${
                                data.operating_year - 1
                              }].sras_reserved_energy_storage_capacity`
                            ]
                          }
                        />
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          readonly={!formData.is_sras_contract}
                          value={data.sras_contract_value.toString()}
                          onChange={(v) => handleContractChange(data.year, 'sras_contract_value', v)}
                          error={errors[`network_support_services[${data.operating_year - 1}].sras_contract_value`]}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </AccordionPanel>
            <AccordionPanel
              panelId='p5'
              label={
                <div className={Style.p1Main}>
                  <h3 className={Style.p1Head}>
                    <Checkbox
                      label='VCAS Contract'
                      disabled={!formData.network_support_services_intended}
                      checked={formData.is_vcas_contract}
                      onChange={(e: boolean) => handleFieldChange('is_vcas_contract', e)}
                    />
                  </h3>
                  <div className={Style.p1Note}>
                    Notes: Leave VCAS Network support contract value as $0 if model to be run to value VCAS service
                    <p className={Style.notePara}>
                      * Different value for 'Real power capability when providing VCAS' to be entered if there is any
                      reduction from rated power to provide VCAS service
                    </p>
                    <p className={Style.notePara}>
                      * Leave VCAS contract value as $0 if model to be run to value VCAS service
                    </p>
                  </div>
                </div>
              }
            >
              <Grid row gap={12} cssClass={Style.p10}>
                <Table headers={vcasContractHeaders}>
                  {formData.network_support_services.map((data) => (
                    <TableRow key={data.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          readonly={!formData.is_vcas_contract}
                          value={data.vcas_real_power_capability.toString()}
                          onChange={(v) => handleContractChange(data.year, 'vcas_real_power_capability', v)}
                          error={
                            errors[`network_support_services[${data.operating_year - 1}].vcas_real_power_capability`]
                          }
                        />
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        <FormInput
                          type='text'
                          readonly={!formData.is_vcas_contract}
                          value={data.vcas_contract_value.toString()}
                          onChange={(v) => handleContractChange(data.year, 'vcas_contract_value', v)}
                          error={errors[`network_support_services[${data.operating_year - 1}].vcas_contract_value`]}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </AccordionPanel>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  )
}
