export const ScenarioDefaultValues = {
  projectID: 0,
  name: '',
  forecast_data_id: '',
  description: '',
  project_title: '',
  project_life_years: '',
  period: 0,
  interval: 0,
  year_of_deployment: '',
  first_year_of_commercial_operation: '',
  rated_discharge_power: 0,
  rated_discharge_power_unit: 'MW',
  rated_charge_power: 0,
  rated_charge_power_unit: 'MW',
  bol_storage_capacity: 0,
  BOL_storage_capacity_unit: 'MW',
  vcas_rates_mvar: 0,
  VCAS_rates_MVAr_unit: 'MVAr',
  overload_discharge_power: 0,
  Overload_discharge_power_unit: 'MW',
  overload_charge_power: 0,
  Overload_charge_Power_unit: 'MW',
  inverter_capacity: 0,
  inverter_capacity_unit: 'MVA',
  is_client_nominated_mef_avail: false,
  r1_max_registered_capacity: '',
  r6_max_registered_capacity: '',
  r60_max_registered_capacity: '',
  r5_max_registered_capacity: '',
  l1_max_registered_capacity: '',
  l6_max_registered_capacity: '',
  l60_max_registered_capacity: '',
  l5_max_registered_capacity: '',
  reg_raise_max_enablement: '',
  reg_lower_max_enablement: '',
  r1_max_enablement: '',
  r5_max_enablement: '',
  r6_max_enablement: '',
  r60_max_enablement: '',
  l1_max_enablement: '',
  l5_max_enablement: '',
  l6_max_enablement: '',
  l60_max_enablement: '',
  loss_factors: [],
  Cap_contracting_is_intended: false,
  cap_contracts: [],
  network_support_services_intended: true,
  Inertia_contract: false,
  selected_inertia_constant: 0,
  rocof_design_criteria: 0,
  network_support_services: [],
  is_sips_contract: false,
  is_sras_contract: false,
  is_vcas_contract: false,
  standby_power_consumption: 0,
  Standby_Power_Consumption_Units: '1',
  default_warrant_cycle: 0,
  Default_warranted_cycling_per_day: '1',
  bess_inputs: [],
  repex: [],
  capex: [],
  is_manual_ltsa_input: false,
  is_manual_warranty_input: false,
  commercial_parameters: [],
  bess_power_rating: '0',
  bess_energy_storage_capacity: '0',
  o_and_m_inflation_rate: '0',
  pop_wholesale_energy_generation: '0',
  pop_wholesale_energy_load: '0',
  pop_reg_fcas: '0',
  pop_contingency_fcas: '0',
  standard_inflation_rate: '0',
  total_project_cost_uplift: '0',
  npv_discount_rate: '0',

  solve_interval_hours: '',
  window_hours: '',
  time_res: 0,
  rreg_utilisation: '0',
  lreg_utilisation: '0',
  inertia_utilisation: '0',
  is_non_market_service: false,

  generating_profile_id: '',
  scenario_project_type: '',
  hybrid_generation_type: '',
  coupled_type: '',
  max_inverter_capacity_out: '',
  max_inverter_capacity_out_unit: 'MW',
  max_inverter_capacity_in: '',
  max_inverter_capacity_in_unit: 'MW',
  max_bess_inverter_capacity_out: '',
  max_bess_inverter_capacity_out_unit: 'MW',
  max_bess_inverter_capacity_in: '',
  max_bess_inverter_capacity_in_unit: 'MW',
  max_vre_inverter_capacity_out: '',
  max_vre_inverter_capacity_out_unit: 'MW',
  max_energy_output_to_grid: '',
  max_energy_output_to_grid_unit: 'MW',
  max_energy_input_from_grid: '',
  max_energy_input_from_grid_unit: 'MW',
  re_generation_nameplate: '',
  generation_efficieny: '',
  ac_charging_efficieny: '',
  dc_charging_efficieny: '',
  transfer_efficieny: '',
}

export const CommercialParameters: { label: string; value: string; keyName: string }[] = [
  { label: 'O&M + Repex Inflation Rate (annual, real basis)', value: '0', keyName: 'o_and_m_inflation_rate' },
  { label: 'Percent of Perfect - Wholesale Energy Generation', value: '0', keyName: 'pop_wholesale_energy_generation' },
  { label: 'Percent of Perfect - Wholesale Energy Load', value: '0', keyName: 'pop_wholesale_energy_load' },
  { label: 'Percent of Perfect - Reg FCAS', value: '0', keyName: 'pop_reg_fcas' },
  { label: 'Percent of Perfect - Contingency FCAS', value: '0', keyName: 'pop_contingency_fcas' },
  { label: 'Standard Inflation Rate', value: '0', keyName: 'standard_inflation_rate' },
  { label: 'Discount rate', value: '0', keyName: 'npv_discount_rate' },
]
