import {
  Button,
  Container,
  DateInput,
  Dropdown,
  FormInput,
  Grid,
  Icon,
  InfoTooltip,
  Radio,
  TextTooltip,
  useToast,
} from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import {
  fetchForecastingDetails,
  fetchTablePreviewData,
  postGeneralProfileMultiFormData,
  putGeneralProfileMultiFormData,
} from 'src/api/ForecastService'
import LoadingScreen from 'src/components/LoadingScreen'
import { ERROR_MESSAGES } from 'src/enums/validationConstants'
import { GeneratingTableModelItem } from 'src/models/api/ForecastingTableModel'
import {
  forecastDataSelector,
  getAllForecastData,
  inProductedSecretState,
  selectedProjectIdState,
  uploadGeneralProfileFormState,
} from 'src/stores/AppStore'
import * as Yup from 'yup'
import { ReactComponent as FileIcon } from '../../assets/fileicon.svg'
import Style from '../../styles/UploadForecast.module.sass'
import { isRoleEmpty } from 'src/helpers/appRoles'
import GeneratingTablePreview from './GeneratingTablePreview'

const FILE_UPLOAD_MESSAGE = 'Drop files to upload or'
const FILE_TYPE_SUPPORT_MESSAGE = 'List of supported file types'
const LEAP_YEAR_LABEL = 'Does this forecast include a Leap Year?'
const DESCRIPTION_LABEL = 'Description'
const GENERATING_PROFILE = 'Generating Profile'
const FILE_UPLOAD_ERROR = 'An error occurred while uploading generating profile!'

const intervalHeader = [
  { id: 5, label: '5 minutes' },
  { id: 10, label: '10 minutes' },
  { id: 15, label: '15 minutes' },
  { id: 30, label: '30 minutes' },
  { id: 60, label: '60 minutes' },
]

const uploadForecastFileTypes = ['.csv']

const validationGenerationProfileSchemas = {
  create: Yup.object().shape({
    interval: Yup.string().required(ERROR_MESSAGES.requiredField),
    start_date: Yup.string().required(ERROR_MESSAGES.requiredField),
    period: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      .integer(ERROR_MESSAGES.numberInteger)
      .min(1, ERROR_MESSAGES.numberMin)
      .required(ERROR_MESSAGES.requiredField),
    file: Yup.mixed<File>()
      .test('fileType', `Unsupported file type. Supported types: ${uploadForecastFileTypes.join(', ')}`, (value) => {
        if (!value) return true
        const file = value as File
        const fileType = `.${file.name.split('.').pop()?.toLowerCase()}`
        return uploadForecastFileTypes.includes(fileType)
      })
      .required(ERROR_MESSAGES.requiredFile),
    description: Yup.string().max(5000, ERROR_MESSAGES.descriptionMax).required(ERROR_MESSAGES.requiredField),
    profile_type: Yup.string().required(ERROR_MESSAGES.requiredField),
  }),
  edit: Yup.object().shape({
    interval: Yup.string().required(ERROR_MESSAGES.requiredField),
    start_date: Yup.string().required(ERROR_MESSAGES.requiredField),
    period: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      .integer(ERROR_MESSAGES.numberInteger)
      .min(1, ERROR_MESSAGES.numberMin)
      .required(ERROR_MESSAGES.requiredField),
    file: Yup.mixed<File>()
      .nullable()
      .test('fileType', `Unsupported file type. Supported types: ${uploadForecastFileTypes.join(', ')}`, (value) => {
        if (!value) return true
        const file = value as File
        const fileType = `.${file.name.split('.').pop()?.toLowerCase()}`
        return uploadForecastFileTypes.includes(fileType)
      }),
    description: Yup.string().max(5000, ERROR_MESSAGES.descriptionMax).required(ERROR_MESSAGES.requiredField),
    profile_type: Yup.string().required(ERROR_MESSAGES.requiredField),
  }),
}

interface UploadGenerationProps {
  isEdit?: boolean
}

const GenerateProfile: React.FC<UploadGenerationProps> = ({ isEdit = false }) => {
  if (appInsights) {
    appInsights.trackPageView({ name: isEdit ? 'EditGeneratingProfile' : 'CreateGeneratingProfile' })
  }
  const SecretState = useRecoilValue(inProductedSecretState)
  const showToast = useToast()
  const navigate = useNavigate()
  const { forecastId: initialForecastId } = useParams<{ forecastId?: string }>()
  const [formData, setFormData] = useRecoilState(uploadGeneralProfileFormState)
  const [progressPercentage, setProgressPercentage] = useState<number>()
  const refreshFetchItems = useRecoilRefresher_UNSTABLE(forecastDataSelector)
  const refreshFetchAllForecastItems = useRecoilRefresher_UNSTABLE(getAllForecastData)
  const projectId = useRecoilValue(selectedProjectIdState)
  const [tableDataGeneratingProfile, setTableDataGeneratingProfile] = useState<GeneratingTableModelItem[] | null>(null)
  const [loadingText, setLoadingText] = useState('')
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [previewCsvUrl, setPreviewCsvUrl] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const [errors, setErrors] = useState({
    description: '',
    period: '',
    start_date: '',
    interval: '',
    profile_type: null,
    file: '',
    is_leap_year_included: '',
  })

  const resetFormData = useCallback(() => {
    setFormData({
      description: '',
      name: '',
      interval: '',
      start_date: '',
      period: '',
      profile_type: 2,
      is_leap_year_included: false,
    })
  }, [setFormData])

  const fetchGenerationData = useCallback(
    async (forecastId: string) => {
      setLoading(true)
      try {
        const response = await fetchForecastingDetails(forecastId)
        if (response) {
          setFormData({
            description: response?.['description'],
            name: response?.['name'],
            is_leap_year_included: response?.['is_leap_year_included'],
            period: response?.['period'],
            start_date: response?.['start_date'],
            interval: response?.['interval'],
            profile_type: response?.['profile_type'] ?? '2',
          })

          if (response?.['file_processing_status']['name']) {
            const tablePreviewGenerationResponse = await fetchTablePreviewData(Number(initialForecastId))
            if (tablePreviewGenerationResponse) {
              setTableDataGeneratingProfile(tablePreviewGenerationResponse)
            }
          }
        }
      } catch (error) {
        showToast.addToast({ type: 'error', message: 'Failed to fetch generating profile data!', timeout: 3000 })
      } finally {
        setLoading(false)
      }
    },
    [setFormData, showToast, initialForecastId],
  )

  useEffect(() => {
    if (!isEdit) {
      resetFormData()
    } else if (isEdit && initialForecastId) {
      fetchGenerationData(initialForecastId)
    }
  }, [isEdit, initialForecastId, resetFormData, fetchGenerationData])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setLoadingText(isEdit ? 'Updating Generating Profile' : 'Uploading Generating Profile')

    if (selectedFile) {
      setFormData((prev) => ({ ...prev, name: selectedFile.name }))
    }

    let newErrors = {
      description: '',
      file: '',
      period: '',
      start_date: '',
      interval: '',
      profile_type: null,
      is_leap_year_included: '',
    }

    try {
      const validationGenerationSchema = isEdit
        ? validationGenerationProfileSchemas.edit
        : validationGenerationProfileSchemas.create

      await validationGenerationSchema.validate(
        {
          description: formData.description,
          period: formData.period,
          start_date: formData.start_date,
          interval: formData.interval,
          profile_type: formData.profile_type,
          file: selectedFile,
        },
        { abortEarly: false },
      )

      if (!isEdit && !selectedFile) {
        newErrors.file = 'Please select a file'
        setErrors(newErrors)
        return
      }

      setErrors({
        description: '',
        period: '',
        profile_type: null,
        file: '',
        start_date: '',
        interval: '',
        is_leap_year_included: '',
      })
      setLoading(true)

      const generatingProfileResponse = isEdit
        ? await putGeneralProfileMultiFormData(initialForecastId, projectId, formData)
        : await postGeneralProfileMultiFormData(projectId, formData, selectedFile, onUploadProgress)

      if (
        generatingProfileResponse.success ||
        generatingProfileResponse.status === 200 ||
        generatingProfileResponse.status === 201
      ) {
        refreshFetchAllForecastItems()
        showToast.addToast({
          type: 'success',
          message: `${GENERATING_PROFILE} ${isEdit ? 'updated' : 'uploaded'} successfully`,
          timeout: 3000,
        })
        navigate(`/dashboard/${projectId}/forecasting`)
      } else {
        showToast.addToast({
          type: 'error',
          message: `${FILE_UPLOAD_ERROR}`,
          timeout: 3000,
        })
        setSelectedFile(null)
      }
    } catch (generationProfileError) {
      if (generationProfileError instanceof Yup.ValidationError) {
        generationProfileError.inner.forEach((error) => {
          if (error.path) {
            newErrors = { ...newErrors, [error.path]: error.message }
          }
        })
        setErrors(newErrors)
      } else {
        showToast.addToast({
          type: 'error',
          message: `${FILE_UPLOAD_ERROR}`,
          timeout: 3000,
        })
        setSelectedFile(null)
      }
    } finally {
      refreshFetchItems()
      setLoading(false)
    }
  }

  const onUploadProgress = (progress: number) => {
    setProgressPercentage(progress)
  }

  const handleChange = (fieldName: string) => (value: string | boolean) => {
    setFormData((prev) => ({ ...prev, [fieldName]: value }))
    setErrors((prev) => ({ ...prev, [fieldName]: '' }))
  }

  const handleIntervalChange = (value: string | number, interval: string) => {
    setFormData((prev) => ({ ...prev, [interval]: value }))
    setErrors((prev) => ({ ...prev, [interval]: '' }))
  }

  const handleLeapYearChange = (value: string) => {
    let isLeapYearIncluded: boolean | null = null
    if (value === 'yes') {
      isLeapYearIncluded = true
    } else if (value === 'no') {
      isLeapYearIncluded = false
    }
    setFormData((prev) => ({ ...prev, is_leap_year_included: isLeapYearIncluded }))
    setErrors((prev) => ({ ...prev, is_leap_year_included: '' }))
  }

  const handleGoBack = useCallback(() => {
    navigate(`/dashboard/${projectId}/forecasting`)
  }, [navigate, projectId])

  const handleGenerationProfile = (id: string, keyname: string) => {
    if (isEdit) {
      console.log('hi')
    }
    setFormData((prev) => ({ ...prev, [keyname]: Number(id) }))
    setErrors((prev) => ({ ...prev, [keyname]: '' }))
  }

  const handleProfileDateChange = async (dates: { startDate: Date | null; endDate: Date | null }) => {
    const preferredDate = dates.startDate
    const adjustedDateTime = preferredDate
      ? new Date(preferredDate.getTime() - preferredDate.getTimezoneOffset() * 60000)
      : ''
    setFormData((prev) => ({
      ...prev,
      start_date: adjustedDateTime ? adjustedDateTime.toISOString().split('T')[0] : '',
    }))
  }

  const handleDragOver = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
  }

  const handleClearPic = () => {
    setSelectedFile(null)
  }

  const handleForecastCsvUpload = (files: FileList | null) => {
    if (files) {
      const fileArray = Array.from(files)
      setSelectedFile(fileArray.length > 0 ? fileArray[0] : null)
    } else {
      setSelectedFile(null)
    }
  }

  const handleFileDrop = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (file) {
      setSelectedFile(file)
    }
  }

  useEffect(() => {
    if (selectedFile) {
      setPreviewCsvUrl(URL.createObjectURL(selectedFile))
    } else {
      setPreviewCsvUrl(null)
    }
  }, [selectedFile])

  return (
    <div>
      {loading ? (
        <LoadingScreen text={loadingText} progress={isEdit ? undefined : progressPercentage} />
      ) : (
        <>
          <div className={Style.mainBlock}>
            <div className={Style.goBack}>
              <Button
                type='custom'
                cssClass={Style.goBackBtn}
                onClick={handleGoBack}
                label='Go back'
                icon='arrow_back_ios'
                size='small'
              />
              <h2 className={Style.titleHead}>
                {isEdit ? 'Edit Upload Generation Profile' : 'Upload Generation Profile'}
              </h2>
            </div>
          </div>
          <Container fluid cssClass={Style.paddingX} style={{ gap: '20px' }}>
            <div className={Style.projectForm}>
              <div className={Style.generateProfileInfo}>
                <div className={Style.firstLine}>
                  <Icon type='info' size='24px' className={Style.infoIcon} outlined />
                  Please ensure that the uploaded file contains the following column headers, The Column headers must
                  exactly match for the file to be processed.
                </div>
                <div className={Style.secondLine}>
                  DateTime - Date and Time of the price in YYYY-MM-DD hh:mm:ss format
                </div>
                <div className={Style.thirdLine}>DC Power</div>
              </div>
              <Grid row gap={12}>
                <Grid item lg={4}>
                  <div className={classNames(Style.formGroup, 'input_forms')}>
                    <Dropdown
                      label='Interval'
                      items={intervalHeader}
                      selectedItem={formData.interval}
                      onSelectItem={(v) => handleIntervalChange(v, 'interval')}
                      required={true}
                      optionsHeight={200}
                    />
                    {errors.interval && <p className={Style.error}>{errors.interval}</p>}
                  </div>
                </Grid>
                <Grid item lg={4}>
                  <div className={classNames(Style.formGroup, 'input_forms')}>
                    <DateInput
                      label='Start Date'
                      dates={{
                        startDate: formData.start_date ? new Date(formData.start_date) : null,
                        endDate: formData.start_date ? new Date(formData.start_date) : null,
                      }}
                      cssClass='reportDate'
                      onDateChange={handleProfileDateChange}
                      required
                    />
                    {errors.start_date && <p className={Style.error}>{errors.start_date}</p>}
                  </div>
                </Grid>
                <Grid item lg={4}>
                  <FormInput
                    type='number'
                    key='period'
                    label='Period'
                    value={formData.period.toString()}
                    onChange={handleChange('period')}
                    required
                    error={errors.period}
                  />
                </Grid>
                <Grid item lg={12} cssClass={Style.noTopPadding}>
                  <Radio
                    items={[
                      {
                        id: '2',
                        label: 'DC Coupled',
                      },
                      {
                        id: '1',
                        label: 'AC Coupled',
                      },
                    ]}
                    selectedItem={formData.profile_type?.toString()}
                    onItemSelect={(e) => handleGenerationProfile(e, 'profile_type')}
                    horizontal
                  />
                </Grid>
                <div>{errors.profile_type && <span className={Style.error}>{errors.profile_type}</span>}</div>

                <Grid item lg={12}>
                  <div className={Style.formGroup}>
                    <FormInput
                      type='multiline'
                      key='description'
                      label={DESCRIPTION_LABEL}
                      placeholder='Please provide descriptions for this generating profile...'
                      value={formData.description}
                      onChange={handleChange('description')}
                      required
                      multilineLimit={5000}
                    />
                    {errors.description && <span className={Style.errorDescription}>{errors.description}</span>}
                  </div>
                </Grid>
              </Grid>
              <div className={isEdit ? Style.noBorder : Style.formDotContainer}>
                {!isEdit && previewCsvUrl && (
                  <div className={Style.csvPreview}>
                    {selectedFile?.name}
                    <Button
                      type='icon-square-secondary'
                      size='small'
                      icon='delete'
                      cssClass={Style.deleteButton}
                      onClick={handleClearPic}
                    />
                  </div>
                )}

                {!isEdit && !previewCsvUrl && (
                  <Container fluid>
                    <div className={Style.imgaeUploadButtons}>
                      <button
                        className={Style.dropArea}
                        onClick={() => document.getElementById('fileInput')?.click()}
                        onTouchStart={() => document.getElementById('fileInput')?.click()}
                        onDrop={(e) => handleFileDrop(e)}
                        onDragOver={(e) => handleDragOver(e)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            document.getElementById('fileInput')?.click()
                          }
                        }}
                      >
                        <FileIcon height='50' width='100' />
                        <p>
                          {FILE_UPLOAD_MESSAGE} <span className={Style.browse}>Browse</span>
                        </p>
                        <div className={Style.supportedList}>
                          <InfoTooltip show={uploadForecastFileTypes.join(', ')} colour='#1E7b80' />{' '}
                          <TextTooltip show={uploadForecastFileTypes.join(', ')}>
                            {FILE_TYPE_SUPPORT_MESSAGE}
                          </TextTooltip>
                        </div>
                        <input
                          id='fileInput'
                          type='file'
                          className={Style.fileInput}
                          accept={uploadForecastFileTypes.join(',')}
                          onChange={(e) => handleForecastCsvUpload(e.target.files)}
                        />
                      </button>
                    </div>
                  </Container>
                )}
              </div>
              {errors.file && <span className={Style.error}>{errors.file}</span>}
              <div className={Style.formGroup}>
                <Grid row>
                  <Grid item lg={3} xs={12}>
                    <Dropdown
                      label={LEAP_YEAR_LABEL}
                      items={[
                        { id: 'yes', label: 'Yes' },
                        { id: 'no', label: 'No' },
                      ]}
                      selectedItem={formData.is_leap_year_included ? 'yes' : 'no'}
                      onSelectItem={(id) => handleLeapYearChange(id.toString())}
                      optionsHeight={200}
                      required={true}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    {errors.is_leap_year_included && <p className={Style.error}>{errors.is_leap_year_included}</p>}
                  </Grid>
                </Grid>
              </div>

              {isEdit && tableDataGeneratingProfile && <GeneratingTablePreview data={tableDataGeneratingProfile} />}

              <div className={Style.formActions}>
                {isEdit && <Button type='secondary' size='large' label='Cancel' onClick={handleGoBack} />}
                <a href='/assets/files/NEM_GENERATION_PROFILE_TEMPLATE.csv' className={Style.downloadTemplate} download>
                  Download Template File
                </a>
                {SecretState && !isRoleEmpty(SecretState.role) && (
                  <Button
                    type='custom'
                    size='medium'
                    cssClass={Style.uploadForecast}
                    label={isEdit ? 'Save Changes' : 'Upload File'}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </div>
          </Container>
        </>
      )}
    </div>
  )
}

export default GenerateProfile
