import { Container, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import React from 'react'
import { Technicalassumption } from 'src/models/api/ReportDetailResponeModal'
import Style from '../../../styles/Reports.module.sass'

interface TechnicalAssumptionsProps {
  data: Technicalassumption[]
}

const ViewTechnicalAssumptions: React.FC<TechnicalAssumptionsProps> = ({ data }) => {
  return (
    <Container fluid>
      <div className={Style.tbl_container}>
        <Table
          headers={[
            { label: 'Year' },
            { label: 'Energy Storage Retention' },
            { label: 'Round Trip Efficiency' },
            { label: 'RE generation (MWh)' },
          ]}
        >
          {data.map((item) => (
            <TableRow key={item.year}>
              <TableCell cellClass={Style.tableDataPadding}>
                <p>{item.year}</p>
              </TableCell>
              <TableCell cellClass={Style.tableDataPadding}>{item.energy_storage_retention}</TableCell>
              <TableCell cellClass={Style.tableDataPadding}>{item.round_trip_efficiency}</TableCell>
              <TableCell cellClass={Style.tableDataPadding}>{item.re_generation}</TableCell>
            </TableRow>
          ))}
        </Table>
      </div>
    </Container>
  )
}

export default ViewTechnicalAssumptions
