import { useNavigate } from 'react-router-dom'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import ConfirmationModal from 'src/components/ConfirmationModal'
import { forecastDataSelector, selectedProjectIdState } from 'src/stores/AppStore'
import { deleteForecast } from 'src/api/ForecastService'
import { useToast } from '@aurecon-creative-technologies/styleguide'

interface ForecastingDeleteProps {
  forecastId?: number
  onClose: () => void
}

const ForecastingDelete: React.FC<ForecastingDeleteProps> = ({ forecastId, onClose }) => {
  if (appInsights) appInsights.trackPageView({ name: 'DeleteForecast' })
  const navigate = useNavigate()
  const showToast = useToast()
  const projectId = useRecoilValue(selectedProjectIdState)
  const refreshFetchItems = useRecoilRefresher_UNSTABLE(forecastDataSelector)
  const handleCancel = () => {
    onClose()
  }

  const handleDelete = async () => {
    if (forecastId) {
      const response = await deleteForecast(forecastId)
      if (response) {
        showToast.addToast({
          type: 'success',
          message: 'Forecast deleted successfully',
          timeout: 3000,
        })
        refreshFetchItems()
        navigate(`/dashboard/${projectId}/forecasting`)
      } else {
        showToast.addToast({
          type: 'error',
          message: 'Forecast deletion failed',
          timeout: 3000,
        })
      }
    }
    onClose()
  }

  return (
    <ConfirmationModal
      title='Delete Forecast'
      message='Are you sure you want to delete this forecast?'
      onCancel={handleCancel}
      onConfirm={handleDelete}
    />
  )
}

export default ForecastingDelete
