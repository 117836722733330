import React, { useEffect, useState } from 'react'
import { FCASRevenueChartColors } from 'src/config/colors'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { NpvDetails } from 'src/models/api/ReportMappingResponseModel'
import FCASRevenueChart from './FCASRevenueChart'

interface ProcessFCASRevenueChartProps {
  rawData: NpvDetails
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  sectionId: string
}

interface ProcessChartData {
  labels: string[]
  datasets: {
    label: string
    data: number[]
    backgroundColor: string
  }[]
}

const ProcessFCASRevenueChart: React.FC<ProcessFCASRevenueChartProps> = ({ rawData, setFormData, sectionId }) => {
  const [chartData, setChartData] = useState<ProcessChartData>()

  useEffect(() => {
    if (!rawData) return

    const { contingency_fcas, regulation_fcas } = rawData

    const processedChartData = {
      labels: contingency_fcas.map((item) => item.year.toString()),
      datasets: [
        {
          label: 'R1 FCAS Revenue',
          data: contingency_fcas.map((item) => item.r1_rev_at),
          backgroundColor: FCASRevenueChartColors.r1,
        },
        {
          label: 'R6 FCAS Revenue',
          data: contingency_fcas.map((item) => item.r6_rev_at),
          backgroundColor: FCASRevenueChartColors.r6,
        },
        {
          label: 'R60 FCAS Revenue',
          data: contingency_fcas.map((item) => item.r60_rev_at),
          backgroundColor: FCASRevenueChartColors.r60,
        },
        {
          label: 'R5 FCAS Revenue',
          data: contingency_fcas.map((item) => item.r5_rev_at),
          backgroundColor: FCASRevenueChartColors.r5,
        },
        {
          label: 'L1 FCAS Revenue',
          data: contingency_fcas.map((item) => item.l1_rev_at),
          backgroundColor: FCASRevenueChartColors.l1,
        },
        {
          label: 'L6 FCAS Revenue',
          data: contingency_fcas.map((item) => item.l6_rev_at),
          backgroundColor: FCASRevenueChartColors.l6,
        },
        {
          label: 'L60 FCAS Revenue',
          data: contingency_fcas.map((item) => item.l60_rev_at),
          backgroundColor: FCASRevenueChartColors.l60,
        },
        {
          label: 'L5 FCAS Revenue',
          data: contingency_fcas.map((item) => item.l5_rev_at),
          backgroundColor: FCASRevenueChartColors.l5,
        },
        {
          label: 'Regulation Raise FCAS Revenue',
          data: regulation_fcas.map((item) => item.fr_fcas_rev_at),
          backgroundColor: FCASRevenueChartColors.reg_raise,
        },
        {
          label: 'Regulation Lower FCAS Revenue',
          data: regulation_fcas.map((item) => item.fl_fcas_rev_at),
          backgroundColor: FCASRevenueChartColors.reg_lower,
        },
      ],
    }

    setChartData(processedChartData)

    setFormData((prevFormData) => ({
      ...prevFormData,
      report_detail: {
        ...prevFormData.report_detail,
        report_section: prevFormData.report_detail.report_section.map((section) =>
          section.section_id === Number(sectionId)
            ? { ...section, chart_data: JSON.stringify(processedChartData) }
            : section,
        ),
      },
    }))
  }, [rawData, setFormData, sectionId])

  return chartData ? <FCASRevenueChart chartData={chartData} /> : null
}

export default ProcessFCASRevenueChart
