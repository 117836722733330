import { Button, Modal, useModal } from '@aurecon-creative-technologies/styleguide'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { downloadReportPDFFile } from 'src/api/ReportService'
import ReportDelete from 'src/pages/Reports/ReportDelete'
import { inProductedSecretState, selectedProjectIdState } from 'src/stores/AppStore'
import Style from '../styles/ForecastingList.module.sass'
import { isRoleEmpty } from 'src/helpers/appRoles'

interface ReportsActionButtonsProps {
  reportId: number
  reportFilePath?: string
}

const ReportsActionButtons: React.FC<ReportsActionButtonsProps> = ({ reportId, reportFilePath }) => {
  const { isShowing, toggleModal } = useModal(false)
  const SecretState = useRecoilValue(inProductedSecretState)
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null)
  const navigate = useNavigate()
  const projectId = useRecoilValue(selectedProjectIdState)

  const handleDeleteClick = () => {
    toggleModal()
  }

  const handleCloseModal = () => {
    toggleModal()
  }

  const gotoViewReport = () => {
    navigate(`/dashboard/${projectId}/clonereport/${reportId}`)
  }

  const handleDownloadClick = async () => {
    try {
      await downloadReportPDFFile(reportId.toString(), (progress) => {
        setDownloadProgress(progress)
      })
      setDownloadProgress(null)
    } catch (error) {
      console.error('Failed to download pdf:', error, downloadProgress)
      setDownloadProgress(null)
    }
  }

  const renderModalContent = () => {
    return <ReportDelete reportId={reportId} onClose={handleCloseModal} />
  }

  return (
    <div className={Style.buttonCont}>
      {reportFilePath && (
        <Button
          type='icon-square'
          size='small'
          icon='download_for_offline'
          onClick={handleDownloadClick}
          cssClass={Style.iconBtn}
        />
      )}
      {SecretState && !isRoleEmpty(SecretState.role) && (
        <Button type='icon-square' size='small' icon='content_copy' cssClass={Style.iconBtn} onClick={gotoViewReport} />
      )}
      {SecretState && !isRoleEmpty(SecretState.role) && (
        <Button
          type='icon-square'
          size='small'
          icon='delete_forever'
          onClick={handleDeleteClick}
          cssClass={Style.iconBtn}
        />
      )}
      <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        size='medium'
        cssClass='modalBackgroundWhite'
        isCloseButton={true}
        isOverlay={true}
        shouldOverlayClose={true}
      >
        <div>{renderModalContent()}</div>
      </Modal>
    </div>
  )
}

export default ReportsActionButtons
