import { deleteAsync, getAsync, postAsync } from 'src/helpers/apiRequest'
import { ScenarioRequestModal } from 'src/models/api/ScenarioRequestModal'
import { ScenariosListModel } from 'src/models/api/ScenariosListModel'
import { getToken } from './TokenManager'
import { dateToString, formatDate } from 'src/helpers/utils'

import { ValidateScenarioNameResponseModel } from 'src/models/api/ValidateScenarioNameResponseModel'
import { ValidateScenarioNameRequestModel } from 'src/models/api/ValidateScenarioNameRequestModel'

export const fetchScenarioData = async (
  selectedProjectId: number,
  page: number,
  pageSize: number,
  nameFilters: string[],
  yearsFilter: string,
  createdDateFilters: { startDate: Date | null; endDate: Date | null },
) => {
  try {
    const token = await getToken()
    const requestbody: ScenarioRequestModal = {
      token: token,
    }
    const requestForecastParams: Record<string, string> = {
      project_id: selectedProjectId.toString(),
      sort_column: 'created_at',
      sort_order: 'asc',
      page: page.toString(),
      size: pageSize.toString(),
    }

    if (yearsFilter) {
      requestForecastParams.duration_years = yearsFilter
    }

    if (createdDateFilters.startDate) {
      requestForecastParams.date_from = formatDate(dateToString(createdDateFilters.startDate))
    }

    if (createdDateFilters.endDate) {
      requestForecastParams.date_to = formatDate(dateToString(createdDateFilters.endDate))
    }

    const requestParamsString = new URLSearchParams(requestForecastParams).toString()

    const forecastFilesQueryString = nameFilters
      .map((filter) => `forecast_file_names=${encodeURIComponent(filter)}`)
      .join('&')

    const finalQueryString = `${forecastFilesQueryString}&${requestParamsString}`

    const baseUri = `/v1/scenarios`
    const response = await getAsync<ScenarioRequestModal, ScenariosListModel>(
      `${baseUri}?${finalQueryString}`,
      requestbody,
    )
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

export const deleteScenario = async (scenarioId: number) => {
  try {
    const token = await getToken()
    const requestbody: ScenarioRequestModal = {
      token: token,
    }
    const baseUri = `/v1/scenarios/${scenarioId}`

    const response = await deleteAsync<ScenarioRequestModal, ScenariosListModel>(baseUri, requestbody)
    return response
  } catch (error) {
    console.error('Error deleting forecast:', error)
    throw error
  }
}

export const validateScenarioName = async (formData: ValidateScenarioNameRequestModel) => {
  try {
    const baseUri = `/v1/scenarios/validation`
    const token = await getToken()
    const requestbody: ValidateScenarioNameRequestModel = {
      token: token,
      ...formData,
    }
    const response = await postAsync<ValidateScenarioNameRequestModel, ValidateScenarioNameResponseModel>(
      baseUri,
      requestbody,
    )
    return response
  } catch (error) {
    throw new Error(`Error validating scenario data: ${error}`)
  }
}
