import { Container, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import Style from '../../../styles/Reports.module.sass'

interface ProjectInputsProps {
  standby_power_consumption?: number | string
  default_warrant_cycle?: number | string
}

const ProjectInputs: React.FC<ProjectInputsProps> = ({
  standby_power_consumption = '-',
  default_warrant_cycle = '-',
}) => {
  const sampleData = [
    { label: 'Average permissible cycles', value: default_warrant_cycle },
    { label: 'Standby power consumption', value: standby_power_consumption },
  ]

  return (
    <Container fluid>
      <div className={`${Style.tbl_container}`}>
        <Table headers={[{ label: 'Project inputs' }, { label: 'Assumption' }]}>
          {sampleData.map((item) => (
            <TableRow key={item.label}>
              <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
                <p>{item.label}</p>
              </TableCell>
              <TableCell cellClass={Style.tableDataPadding}>
                <p>{item.value}</p>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </div>
    </Container>
  )
}

export default ProjectInputs
