import { ChartConfiguration } from 'chart.js/auto'
import React, { useEffect, useMemo } from 'react'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { SampleDispatchProfiles } from 'src/models/api/ReportMappingResponseModel'
import { updateChartDataInForm } from 'src/api/ReportService'
import SampleStateChargeProfilesChart from './SampleStateChargeProfilesChart'

interface SampleStateChargeProfilesDataProcessorProps {
  data: SampleDispatchProfiles[]
  sectionId: string
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
}

const consolidateDailyAverages = (data: SampleDispatchProfiles[]): SampleDispatchProfiles[] => {
  const dailyData: {
    [key: string]: { totalSoc: number; totalMwNet: number; count: number }
  } = {}

  data.forEach((item) => {
    const date = new Date(item.datetime).toISOString().split('T')[0] // Get only the date part
    if (!dailyData[date]) {
      dailyData[date] = { totalSoc: 0, totalMwNet: 0, count: 0 }
    }
    dailyData[date].totalSoc += Number(item.soc) || 0
    dailyData[date].totalMwNet += Number(item.mw_net) || 0
    dailyData[date].count += 1
  })

  return Object.keys(dailyData).map((date) => ({
    datetime: new Date(date),
    energy_load: 0,
    energy_gen: 0,
    rreg: 0,
    lreg: 0,
    day: new Date(date).getDate(),
    soc: dailyData[date].totalSoc / dailyData[date].count,
    mw_net: dailyData[date].totalMwNet / dailyData[date].count,
  }))
}

const generateLabels = (data: SampleDispatchProfiles[]): string[] =>
  data.map((item) => new Date(item.datetime).toLocaleDateString())

const generateDatasets = (data: SampleDispatchProfiles[]): ChartConfiguration<'line'>['data']['datasets'] => {
  const keys: Array<keyof SampleDispatchProfiles> = ['soc', 'mw_net']

  const labelsForKeys = ['SOC', 'MW Net']

  return keys.map((key, index) => ({
    label: labelsForKeys[index],
    data: data.map((item) => Number(item[key]) || 0),
    fill: false,
    tension: 0.4,
  }))
}

const ProcessSampleStateChargeProfilesData: React.FC<SampleStateChargeProfilesDataProcessorProps> = ({
  data,
  sectionId,
  setFormData,
}) => {
  const consolidatedData = useMemo(() => consolidateDailyAverages(data), [data])

  const chartData = useMemo(() => {
    return {
      labels: generateLabels(consolidatedData),
      datasets: generateDatasets(consolidatedData),
    }
  }, [consolidatedData])

  useEffect(() => {
    updateChartDataInForm(setFormData, sectionId, 'chart1', chartData)
  }, [chartData, sectionId, setFormData])

  return <SampleStateChargeProfilesChart data={chartData} />
}

export default ProcessSampleStateChargeProfilesData
