import React from 'react'
import { InfoTooltip } from '@aurecon-creative-technologies/styleguide'
import Style from '../../styles/CreateScenario.module.sass'
interface IHeader {
  label: string
  key: string
}

interface CustomTableProps {
  headers: IHeader[]
  rows: React.ReactNode
}

const CustomTable: React.FC<CustomTableProps> = ({ headers, rows }) => {
  const getHeaderWithTooltip = (label: string) => {
    if (label === 'NMS Value Per Hour') {
      return (
        <div className={Style.headerToolTip}>
          {label} {''}
          <InfoTooltip show='Energy allocated for Non-market Services per hour' />
        </div>
      )
    }
    return <div>{label}</div>
  }

  return (
    <table className={Style.customTable}>
      <thead>
        <tr className={Style.customTableRow}>
          {headers.map((header) => (
            <th key={header.key} className={Style.customTableHeader}>
              {getHeaderWithTooltip(header.label)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

export default CustomTable
