import { Button, Loader, Modal, useModal } from '@aurecon-creative-technologies/styleguide'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { downloadForecastFile, downloadForecastLog, fetchTablePreviewData } from 'src/api/ForecastService'
import { ForecastingTableModelItem, GeneratingTableModelItem } from 'src/models/api/ForecastingTableModel'
import ForecastingDelete from '../pages/Forecasting/ForecastingDelete'
import TablePreview from '../pages/Forecasting/TablePreview'
import Style from '../styles/ForecastingList.module.sass'
import { useRecoilValue } from 'recoil'
import { inProductedSecretState, selectedProjectIdState } from 'src/stores/AppStore'
import { isRoleEmpty } from 'src/helpers/appRoles'
import GeneratingTablePreview from 'src/pages/Forecasting/GeneratingTablePreview'

interface ForecastActionButtonsProps {
  forecastId: number
  status: 'IN_PROGRESS' | 'FAILED' | 'SUCCESSFUL'
  fileType: number
}

const ForecastActionButtons: React.FC<ForecastActionButtonsProps> = ({ forecastId, status, fileType }) => {
  const navigate = useNavigate()
  const { isShowing, toggleModal } = useModal(false)
  const SecretState = useRecoilValue(inProductedSecretState)
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null)
  const [tableData, setTableData] = useState<ForecastingTableModelItem[] | null>(null)
  const [tableDataGeneratingProfile, setTableDataGeneratingProfile] = useState<GeneratingTableModelItem[] | null>(null)
  const [isViewClicked, setIsViewClicked] = useState(false)
  const projectId = useRecoilValue(selectedProjectIdState)
  useEffect(() => {
    if (!isShowing) {
      setIsViewClicked(false)
      setTableData(null)
      setTableDataGeneratingProfile(null)
    }
  }, [isShowing])

  const handleEditClick = () => {
    if (fileType === 1) {
      navigate(`/dashboard/${projectId}/forecastingedit/${forecastId}`)
    } else if (fileType === 2) {
      navigate(`/dashboard/${projectId}/forecastinguploadprofile/${forecastId}`)
    }
  }

  const handleDeleteClick = () => {
    setIsViewClicked(false)
    toggleModal()
  }

  const handleCloseModal = () => {
    toggleModal()
  }

  const handleDownloadClick = async () => {
    try {
      await downloadForecastFile(forecastId, (progress) => {
        setDownloadProgress(progress)
      })
      setDownloadProgress(null)
    } catch (error) {
      console.error('Failed to download forecast:', error, downloadProgress)
      setDownloadProgress(null)
    }
  }

  const handleLogDownloadClick = async () => {
    try {
      await downloadForecastLog(forecastId, (progress) => {
        setDownloadProgress(progress)
      })
      setDownloadProgress(null)
    } catch (error) {
      console.error('Failed to download log:', error, downloadProgress)
      setDownloadProgress(null)
    }
  }

  const handleViewClick = async () => {
    setIsViewClicked(true)
    toggleModal()
    try {
      const response = await fetchTablePreviewData(forecastId)
      if (fileType === 1) {
        setTableData(response)
        console.log(response)
      } else {
        setTableDataGeneratingProfile(response)
        console.log(response)
      }
    } catch (error) {
      console.error('Failed to fetch table preview data:', error)
    }
  }

  const renderModalContent = (): JSX.Element => {
    if (isViewClicked) {
      if (fileType === 1) {
        return tableData ? (
          <TablePreview data={tableData} />
        ) : (
          <div>
            <Loader label='loading' />
          </div>
        )
      } else if (fileType === 2) {
        return tableDataGeneratingProfile ? (
          <GeneratingTablePreview data={tableDataGeneratingProfile} />
        ) : (
          <div>
            <Loader label='loading' />
          </div>
        )
      }
    }

    return <ForecastingDelete forecastId={forecastId} onClose={handleCloseModal} />
  }

  return (
    <div className={Style.buttonCont}>
      {status === 'SUCCESSFUL' && (
        <>
          <Button type='icon-square' size='small' icon='pageview' onClick={handleViewClick} cssClass={Style.iconBtn} />
          <Button
            type='icon-square'
            size='small'
            icon='download_for_offline'
            onClick={handleDownloadClick}
            cssClass={Style.iconBtn}
          />
          {SecretState && !isRoleEmpty(SecretState.role) && (
            <Button type='icon-square' size='small' icon='edit' onClick={handleEditClick} cssClass={Style.iconBtn} />
          )}
          {SecretState && !isRoleEmpty(SecretState.role) && (
            <Button
              type='icon-square'
              size='small'
              icon='delete_forever'
              onClick={handleDeleteClick}
              cssClass={Style.iconBtn}
            />
          )}
        </>
      )}
      {status === 'FAILED' && (
        <>
          <Button
            type='icon-square'
            size='small'
            icon='menu_book'
            onClick={handleLogDownloadClick}
            cssClass={Style.iconBtn}
          />
          {SecretState && !isRoleEmpty(SecretState.role) && (
            <Button
              type='icon-square'
              size='small'
              icon='delete_forever'
              onClick={handleDeleteClick}
              cssClass={Style.iconBtn}
            />
          )}
        </>
      )}
      <Modal
        isShowing={isShowing}
        onClose={toggleModal}
        size='medium'
        cssClass='modalBackgroundWhite'
        isCloseButton={true}
        isOverlay={true}
        shouldOverlayClose={true}
      >
        <div>{renderModalContent()}</div>
      </Modal>
    </div>
  )
}

export default ForecastActionButtons
